import {Component, Input, OnInit} from '@angular/core';
import { Booking, PartyInvoice } from '@unleashed/models/booking';

@Component({
  selector: 'ua-call-to-action',
  templateUrl: './call-to-action.component.html',
  styleUrls: ['./call-to-action.component.scss']
})
export class CallToActionComponent implements OnInit {

  @Input() template?: string;
  @Input() possessive = true;
  @Input() groupText = 'the';
  @Input() booking?: Booking;
  @Input() showTime = false;
  @Input() size: 'normal' | 'large' = 'normal';
  @Input() invoice?: PartyInvoice;

  displayText = '';
  textSize = 'fs-5';

  constructor() { }

  ngOnInit(): void {
    if (!this.template) {
      return;
    }

    this.textSize = this.size === 'normal' ? 'fs-5' : 'fs-3';

    this.displayText = !this.invoice?.isSpecialEvent ?? true
        ? this.template.replace('{0}', this.getGuestDisplay())
        : this.template.replace('{0}', this.invoice?.organizationName ?? '');

  }

  getGuestDisplay(): string {
    if (!this.booking?.guestsOfHonor) {
      return '';
    }

    switch (this.booking.guestsOfHonor.length) {
      case 1:
        return `${this.booking.guestsOfHonor[0].firstName}${this.possessive ? '\'s' : ''}`;
      case 2:
        this.textSize = this.size === 'normal' ? 'fs-6' : 'fs-4';
        return `${this.booking.guestsOfHonor[0].firstName} and ${this.booking.guestsOfHonor[1].firstName}${this.possessive ? '\'s' : ''}`;
      default:
        return this.groupText;
    }
  }
}
