<div class="modal" tabindex="-1" id="promoConfirmationModal" data-bs-backdrop="static" data-bs-keyboard="false">
  <div class="modal-dialog">
    <div class="modal-content border border-primary">
      <div class="modal-header bg-primary text-white">
        <h5 class="modal-title">Confirm Promotion</h5>
      </div>
      <div class="modal-body p-4">
        <div class="py-2">
          {{promoConfirmMessage}}
        </div>
        <div class="row gap-2 mt-4">
          <button type="button" class="col btn btn-block btn-primary" (click)="confirm()">
            Yes
          </button>
          <button type="button" class="col btn btn-outline-primary btn-block" (click)="cancel()"
                  data-bs-dismiss="modal">
            No
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
