import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService, ServiceBase } from '@unleashed/api/common';
import { APP_OPTIONS, AppOptions } from '@unleashed/common/config';
import { Brand } from '@unleashed/models/brand/brand';

@Injectable({
  providedIn: 'root'
})
export class BrandsApiService extends ServiceBase {

  subnav = 'brands';

  constructor(@Inject(APP_OPTIONS) appOptions: AppOptions, private apiService: ApiService) {
    super(appOptions.unleashedApiUrl);
  }

  getBrands(brandId: number): Observable<Brand[]> {
    return this.apiService.get<Brand[]>(this.buildUrl(brandId));
  }

  getBrandBySlug(brandSlug: string): Observable<Brand> {
    return this.apiService.get<Brand>(this.buildUrl(brandSlug));
  }

}
