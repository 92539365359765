import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import * as isValidEmail from 'is-valid-email';

export function emailAddressValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    return !isValidEmail(control.value)
      ? {invalidEmail: {value: control.value}}
      : null;
  };
}
