<footer class="fixed-bottom bg-alt pt-2 text-center text-tertiary">
  <p class="lh-1">
    <strong>
      Questions? Contact Us at <a class="text-decoration-none" href="tel:{{phoneNumber}}">{{phoneNumber}}</a>
    </strong>
    <br>
    <sub>Copyright © {{year}} Unleashed Brands. All rights reserved.</sub>
  </p>
</footer>

