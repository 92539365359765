import {Component, Input, OnInit} from '@angular/core';
import {BookingSessionService} from '@unleashed/services/booking';

@Component({
  selector: 'ua-display-price',
  templateUrl: './display-price.component.html',
  styleUrls: ['./display-price.component.scss']
})
export class DisplayPriceComponent implements OnInit {

  @Input() amount?: number;
  @Input() shortenNumber = false;
  currencyCode?: string;
  conversionRate = 1;
  constructor(private sessionService: BookingSessionService) { }

  ngOnInit(): void {
    this.sessionService.getSession()
      .subscribe(session => {
        if (this.amount === undefined) {
          return;
        }
        this.currencyCode = session.park?.currencyCode;
        this.conversionRate = session.park?.currencyConversionRate ?? 1;
      });
  }

}
