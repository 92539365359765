import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { EcommerceModule } from '@unleashed/ecommerce/ecommerce.module';
import { environment } from 'src/environments/environment';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    EcommerceModule
  ],
  providers: [
    { provide: 'googleTagManagerId', useValue: environment.googleTagManager.id }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
