import { Component, Inject, Input } from '@angular/core';
import { BookingSessionService } from '@unleashed/services/booking';
import { BRAND_OPTIONS, BrandOptions } from '@unleashed/common/config';

@Component({
  selector: 'ua-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent {

  @Input() errorCode = 404;
  phoneNumber: string;

  constructor(
    private sessionService: BookingSessionService,
    @Inject(BRAND_OPTIONS) private brandOptions: BrandOptions
  ) {
    this.phoneNumber = brandOptions.phoneNumber;
  }

}
