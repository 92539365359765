<div class="mx-2 mt-5">
  <div class="d-flex justify-content-center">
    <img src="/assets/images/brand-oops.svg" alt="Oops">
  </div>

  <div class="text-center mt-5" *ngIf="errorCode === 404; else genError">
    <p class="h4 text-primary fw-bold">
      We can't seem to find the page you are looking for.
    </p>
  </div>
</div>

<ng-template #genError>
  <div class="text-center">
    <p class="h4 text-primary fw-bold">
      This is embarrassing, something seems to have gone wrong.
    </p>

    <p class="mt-4">
      Please try refreshing the page in a few moments. If the problem persists, contact us at
      <a href="tel:{{phoneNumber}}">{{phoneNumber}}</a>.
    </p>
  </div>
</ng-template>
