<div class="payment-dropin mt-4" *ngIf="accessToken">
  <div class="fw-bold text-center fs-5 mb-2">
    Payment Information
  </div>
  <div>We will charge a payment of <ua-display-price [amount]="booking?.depositAmount"></ua-display-price> to finalize your party. You will then be emailed a confirmation.</div>
  <div class="form-check mt-2" *ngIf="paymentProcessorVersion === 2 && filteredCards && filteredCards.length > 0">
    <input [(ngModel)]="useDefaultCard"  (ngModelChange)="onDefaultChanged()"  id="useDefaultCardCheck" type="checkbox" class="form-check-input" />
    <label class="form-check-label disclaimer" for="useDefaultCardCheck"> Use Default Payment Information</label>
  </div>
  <ng-container *ngIf="(paymentProcessorVersion === 2 && this.useDefaultCard)">
    <label for="defaultCardNumber">Card Number</label>
    <input id="defaultCardNumber" disabled value="{{defaultCard?.cardNumber ?? ''}}" class="form-control"/>
  </ng-container>
  <ng-container *ngIf="(paymentProcessorVersion === 2 && !this.useDefaultCard && this.defaultCard && this.filteredCards && !this.addNewCard)">
    <select [(ngModel)]="defaultCardValue" class="form-select form-control">
      <option value="" disabled>--Select One--</option>
      <option class="text-dark" [value]="card.id" *ngFor="let card of filteredCards" value="{{card.cardNumber}}">{{card.bankName}} - {{card.cardNumber}}</option>
    </select>
    <hr>
    <div>
      <button (click)="addPaymentMethodClick()" class="btn btn-primary btn-lg w-100 fw-bold">Add Payment Method</button>
    </div>
  </ng-container>
  <form [formGroup]="form"
        *ngIf="(paymentProcessorVersion === 2  && !useDefaultCard && addNewCard)">
      <ua-justifi (justifiReady)="setPaymentInitialized($event)"></ua-justifi>
      <div class="pad-form">
        <div *ngIf="isAvs">
          <button (click)="useAccountAddress()" class="btn btn-primary btn-lg w-100 fw-bold">Use Account Address</button>
          <div>
            <label for="fullName">Full Name</label>
            <input
              id="fullName"
              class="form-control"
              type="text"
              placeholder="Enter Full Name"
              formControlName="fullName"
            />
            <ua-display-validation-errors
              name="Full Name"
              [control]="form.controls.fullName"
              [force]="formDirty"
            ></ua-display-validation-errors>
          </div>
          <div>
            <label for="streetAddress">Street Address</label>
            <input
              id="streetAddress"
              class="form-control"
              type="text"
              placeholder="Enter Street Address"
              formControlName="streetAddress"
            />
            <ua-display-validation-errors
              name="Street Address"
              [control]="form.controls.streetAddress"
              [force]="formDirty"
            ></ua-display-validation-errors>
          </div>
          <div>
            <label for="city">City</label>
            <input
              id="city"
              class="form-control"
              type="text"
              placeholder="Enter City"
              formControlName="city"
            />
            <ua-display-validation-errors
              name="City"
              [control]="form.controls.city"
              [force]="formDirty"
            ></ua-display-validation-errors>
          </div>
          <div>
            <label for="state">State</label>
            <select
              [class]="
                form.controls.state.value === -1 ? 'text-muted' : ''
              "
              id="state"
              class="form-select form-control"
              formControlName="state"
            >
              <option value="-1" disabled>State</option>
              <option
                class="text-dark"
                *ngFor="let state of states"
                value="{{ state.stateCode }}"
              >
                {{ state.stateCode }} - {{ state.name }}
              </option>
            </select>
            <ua-display-validation-errors
              name="State"
              [control]="form.controls.state"
              [force]="formDirty"
            >
            </ua-display-validation-errors>
          </div>
        </div>
        <div>
          <label for="zip">Zip</label>
          <input
            id="zip"
            class="form-control"
            type="text"
            placeholder="Enter Zip"
            formControlName="zip"
          />
          <ua-display-validation-errors
            name="zip"
            [control]="form.controls.zip"
            [force]="formDirty"
          ></ua-display-validation-errors>
        </div>
      </div>
  </form>
  <ng-container *ngIf="paymentProcessorVersion === 1">
    <ua-payment (dropinCreated)="setDropin($event)"></ua-payment>
  </ng-container>
  <div *ngIf="braintreeError" class="alert alert-danger text-center mt-2" role="alert">
    {{braintreeError}}
  </div>
   <div *ngIf="justifiError" class="alert alert-danger text-center mt-2" role="alert">
    Please check your card information
  </div>
</div>

<div class="disclaimer fw-bold mt-2">By checking these boxes, I agree to the below terms and conditions.</div>

<div class="form-check mt-2" *ngIf="!user?.impersonated">
  <input id="documents" type="checkbox" class="form-check-input" [(ngModel)]="documentsAcknowledged">
  <label class="form-check-label disclaimer" for="documents">
    By selecting "Pay Now", you agree to the <a [href]="eSignatureLink" target="_blank">E-signature agreement</a>,
    <a [href]="termsLink" target="_blank">Terms of Use</a>,
    and <a [href]="privacyLink" target="_blank">Privacy Policy</a>.
  </label>
</div>
<div class="form-check mt-2">
  <input id="deposit" type="checkbox" class="form-check-input" [(ngModel)]="depositAcknowledged">
  <label class="form-check-label disclaimer" for="deposit">
    <span *ngIf="brandId === 1">
      A non-refundable payment of <ua-display-price [amount]="booking?.depositAmount"></ua-display-price> is required to secure all birthday events.
      If you cancel the event with more than 72 hours’ notice, then you waive the right to the
      deposit but you will not owe anything further for the cancelled event. If you cancel the event
      with less than 72 hours’ notice, then, so long as you reschedule the event to occur within 30 days
      of the originally scheduled event, then we will apply the deposit to the cost of the event.
      If you no-show the event, then you waive the right to recover the deposit and authorize us to charge
      your credit card for the full amount of the event. If you walk out on the event prior to payment,
      then you waive the right to recover the deposit and authorize us to charge your credit card for the
      full amount of the event. Group Sales and Private Park Rentals: a 50% non-refundable deposit is required to book.
      If you cancel your event you will forfeit the full deposit.
    </span>
    <span *ngIf="brandId !== 1">
      A non-refundable payment of <ua-display-price [amount]="booking?.depositAmount"></ua-display-price> is required to secure all
      birthday events.
      If you cancel the party with less than a 72
      hour notice, we will work with you to find another date and time to host your party or you will forfeit the full
      deposit. If you choose to reschedule your party and cancel at a later date you will forfeit the full deposit.
      Deposits are applied to the remaining balance at the end of the party.
      <span *ngIf="showSpecialEventText">Group Sales and Private Park Rentals: a 50% non-refundable deposit is required to book. If you cancel your event you
      will forfeit the full deposit.</span>
    </span>
  </label>
</div>
<div class="form-check mt-2">
  <input id="weather" type="checkbox" class="form-check-input" [(ngModel)]="weatherAcknowledged">
  <label class="form-check-label disclaimer" for="weather">
    Weather: If acts of God or Weather cause the facility to close, your party will be re-scheduled. No refunds will be
    given due to weather or acts of God.
  </label>
</div>
<div class="mt-3">
  <button class="btn btn-primary btn-lg w-100 fw-bold" (click)="payNow()"
          [disabled]="!paymentInitialized || !acknowledged">Checkout</button>
</div>
<div class="my-4">
  <button class="btn btn-outline-primary btn-lg w-100 fw-bold" (click)="back()">Go Back</button>
</div>
