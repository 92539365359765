<ng-container *ngIf="invoice">
  <ua-call-to-action *ngIf="booking" [booking]="booking"
                     [invoice]="invoice"
                     [template]="!invoice.isSpecialEvent ? '{0} Birthday Party' : '{0}' "
                     [size]="'large'"
                     [groupText]="!invoice.isSpecialEvent ? 'The' : ''">
  </ua-call-to-action>
  <hr class="my-2 bg-primary">

  <div class="d-flex justify-content-between align-items-start align-content-start">
    <div class="small">
      <div class="h5 fw-bold text-primary party-format-name mb-0">
        <span *ngIf="showTheme">{{ invoice.partyTheme }} Party</span>
      </div>
      <div>{{invoice.park.brand?.name}}</div>
      <div>{{invoice.park?.name}}</div>
      <div>{{invoice.park?.address?.streetAddress}}</div>
      <div>{{invoice.park?.address?.city}}, {{invoice.park?.address?.state}} {{invoice.park?.address?.zipCode}}</div>
    </div>

    <ua-party-time *ngIf="booking" [booking]="booking" [invoice]="invoice" [align]="'end'"></ua-party-time>
  </div>

  <div class="d-flex justify-content-between align-items-baseline mt-2 package">
    <div class="h2 fw-bold package-label">
      <span [style.color]="invoice.productLevelColor">
        {{ invoice.productLevelName }} Package
      </span>
    </div>
    <div class="h6 package-price">
      <ua-display-price [amount]="invoice.packagePrice"></ua-display-price>
    </div>
  </div>

  <div class="d-flex justify-content-between align-items-end h6 participating-kids">
    <div *ngIf="invoice.isSpecialEvent" class="participating-kids-label">
      Number of Participants ({{invoice.baseParticipantQuantity}} included):
    </div>
    <div *ngIf="!invoice.isSpecialEvent" class="participating-kids-label">
      Number of Participating Kids ({{invoice.baseParticipantQuantity}} included):
    </div>

    <div class="pl-1 participating-kids-qty">
      {{ invoice.totalParticipantQuantity }}
    </div>
  </div>

  <div class="d-flex justify-content-between align-items-end h6 additional-kids"
       *ngIf="invoice.additionalParticipantPrice">
    <div class="additional-kids-label">
      Additional Kid Participant Fees:
    </div>
    <div class="pl-1 additional-kids-price">
      <ua-display-price [amount]="invoice.additionalParticipantPrice"></ua-display-price>
    </div>
  </div>

  <div *ngIf="invoice.adultParticipantQuantity > 0"
       class="d-flex justify-content-between align-items-end pt-4 h6 participating-adults">
    <div class="participating-adults-label">
      Number of Participating Adults (0 included):
    </div>
    <div class="pl-1 participating-adults-qty">
      {{ invoice.adultParticipantQuantity }}
    </div>
  </div>

  <div class="d-flex justify-content-between align-items-end h6 additional-adults"
       *ngIf="invoice.additionalAdultParticipantPrice">
    <div class="additional-adults-label">
      Additional Adult Participant Fees:
    </div>
    <div class="pl-1 additional-adults-price">
      <ua-display-price [amount]="invoice.additionalAdultParticipantPrice"></ua-display-price>
    </div>
  </div>

  <div class="d-flex justify-content-between align-items-end pt-4 fw-bold h6 total-additional-participants"
       *ngIf="invoice.additionalParticipantPrice || invoice.additionalAdultParticipantPrice">
    <div class="total-additional-participants-label">
      Total Cost of Additional Participants:
    </div>
    <div class="pl-1 total-additional-participants-price">
      <ua-display-price [amount]="invoice.additionalParticipantPrice + invoice.additionalAdultParticipantPrice">
      </ua-display-price>
    </div>
  </div>

  <div *ngIf="!invoice.isSpecialEvent && showHangouts">
    <hr class="my-2 bg-primary">
    <div class="h4 fw-bold text-primary">
      Hangout:
    </div>
    <div class="d-flex justify-content-between h6 hangout">
      <div class="hangout-label">
        {{ invoice.resourceTypeName }}
      </div>
      <div class="hangout-price">
        <ua-display-price [amount]="invoice.resourcePrice" *ngIf="invoice.resourcePrice"></ua-display-price>
        <ng-container *ngIf="!invoice.resourcePrice">Included</ng-container>
      </div>
    </div>
  </div>

  <hr class="my-2 bg-primary" *ngIf="invoice.included?.length || invoice.amenities?.length">

  <div class="h4 fw-bold text-primary" *ngIf="invoice.included?.length || invoice.amenities?.length">
    Included:
  </div>
  <ng-container>
    <div class="d-flex justify-content-between h6 included-items" *ngFor="let item of invoice.included">
      <div class="included-item-label">
        {{ item.name }}
      </div>
      <div class="included-item-qty">
        {{ item.quantity }}
      </div>
    </div>
  </ng-container>
  <ng-container>
    <div class="d-flex justify-content-between h6 included-amenities" *ngFor="let item of invoice.amenities">
      <div class="included-amenity-label">
        {{ item }}
      </div>
      <div class="included-amenity-qty">
        Included
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="invoice.addons && invoice.addons.length">
    <hr class="my-2 bg-primary">

    <div class="h4 fw-bold text-primary">
      Additional Items:
    </div>
    <div class="row h6 add-on-items" *ngFor="let item of invoice.addons">
      <div class="col-7 add-on-item-label">
        {{ item.name }}
      </div>
      <div class="col-2 text-end add-on-item-qty">
        {{ item.quantity }}
      </div>
      <div class="col-3 text-end add-on-item-price">
        <ua-display-price [amount]="item.totalPrice"></ua-display-price>
      </div>
    </div>
  </ng-container>


  <hr class="my-2 bg-primary">
  <div class="row h6 party-booking-fee" *ngFor="let item of invoice.fees">
    <div class="col-9 party-booking-fee-label">
      {{ item.name }}
    </div>
    <div class="col-3 text-end party-booking-fee-price">
      <ua-display-price [amount]="item.price"></ua-display-price>
    </div>
  </div>
  <div class="d-flex flex-row justify-content-between h6" *ngIf="invoice.promo">
    <div>
      Promo - {{ invoice.promo.code }}
    </div>
    <div>
      <ua-display-price [amount]="invoice.promo.price"></ua-display-price>
    </div>
  </div>
  <div class="d-flex flex-row justify-content-between h6" *ngIf="invoice.entitlementDiscount">
    <div>
      {{ invoice.entitlementName }}
    </div>
    <div>
      <ua-display-price [amount]="invoice.entitlementDiscount"></ua-display-price>
    </div>
  </div>
  <div class="d-flex flex-row justify-content-between h6 subtotal">
    <div class="subtotal-label">
      Subtotal
    </div>
    <div class="subtotal-price">
      <ua-display-price [amount]="invoice.subtotal"></ua-display-price>
    </div>
  </div>
  <div class="d-flex flex-row justify-content-between h6 tax">
    <div class="tax-label">
      Tax
    </div>
    <div class="tax-price">

      <ng-container *ngIf="invoice.isSpecialEvent; else notSpecialEvent">
        <ua-display-price [amount]="invoice.tax"></ua-display-price>
      </ng-container>

      <ng-template #notSpecialEvent>
        <ng-container *ngIf="invoice.tax === 0; else taxValue">
          Will be calculated on final payment
        </ng-container>
        <ng-template #taxValue>
          <ua-display-price [amount]="invoice.tax"></ua-display-price>
        </ng-template>
      </ng-template>
    </div>
  </div>

  <hr class="my-2 bg-primary">

  <div class="d-flex flex-row justify-content-between h6 fw-bold total">
    <div class="total-label">
      Total
    </div>
    <div class="total-price">
      <ua-display-price [amount]="invoice.total"></ua-display-price>
    </div>
  </div>

  <div class="d-flex flex-row justify-content-between h6 payment" *ngIf="depositPaid">
    <div class="payment-label">
      Payment
    </div>
    <div class="payment-price">
      <ua-display-price [amount]="invoice.deposit"></ua-display-price>
    </div>
  </div>
</ng-container>
