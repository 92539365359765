import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'ua-quantity',
  templateUrl: './quantity.component.html',
  styleUrls: ['./quantity.component.scss']
})
export class QuantityComponent implements OnChanges {

  faPlus = faPlus;
  faMinus = faMinus;

  @Input() loaded = false;
  @Input() count = 0;
  @Input() min?: number;
  @Input() max?: number;
  @Input() gtmTag?: string;

  allowIncrease = false;
  allowDecrease = false;

  @Output() countIncreased = new EventEmitter<void>();
  @Output() countDecreased = new EventEmitter<void>();

  ngOnChanges(): void {
    this.allowIncrease = this.loaded && (this.max === undefined || this.count < this.max);
    this.allowDecrease = this.loaded && this.count > (this.min ?? 0);
  }

  increaseCount(): void {
    this.countIncreased.emit();
  }

  decreaseCount(): void {
    this.countDecreased.emit();
  }

}
