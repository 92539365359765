import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import parsePhoneNumber from 'libphonenumber-js';

export function phoneNumberValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const phoneNumber = parsePhoneNumber(control.value, 'US');
    return !phoneNumber || phoneNumber.nationalNumber.length < 10 || phoneNumber.nationalNumber.length > 20
      ? {invalidPhone: {value: control.value}}
      : null;
  };
}
