import { Component } from '@angular/core';

@Component({
  selector: 'ua-invoice-skeleton',
  templateUrl: './invoice-skeleton.component.html',
  styleUrls: ['./invoice-skeleton.component.scss']
})
export class InvoiceSkeletonComponent {

}
