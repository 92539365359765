<ng-container *ngIf="modifiers && modifiers.length > 0 && hasItemsToSelect">
  <h4 class="text-primary fw-bold mt-3"> Included Items:</h4>
  <form [formGroup]="form">
    <ng-container formArrayName="items">
      <div *ngFor="let g of groups; let i = index">
        <ng-container [formGroupName]="i" *ngIf="displayItems[i].quantity > 0">
          <div class="d-flex mt-2 align-items-center" *ngIf="formItemsParkProducts[i].products.length && !formItemsParkProducts[i].products[0].bundleScheduleId">
            <h5 class="col-5 text-end me-3">
              {{ displayItems[i].name }}
            </h5>
            <select class="form-select" [class]="g.controls.parkProductId.value === 0 ? 'text-muted' : ''"
                    *ngIf="formItemsParkProducts[i].products.length > 1" formControlName="parkProductId">
              <option value="0" disabled>Choose one</option>
              <option class="text-dark"  [value]="item.parkProductId" *ngFor="let item of formItemsParkProducts[i].products">
                {{item.parkProductName }}
              </option>
            </select>

            <select class="form-select" *ngIf="formItemsParkProducts[i].products.length < 2" disabled>
              <option selected value="">Includes {{displayItems[i].quantity}}</option>
            </select>
          </div>
          <div class="d-flex align-items-center">
            <div class="col-5 text-end me-3">
            </div>
            <ua-display-validation-errors name='{{ displayItems[i].name }}' [control]="g.controls.parkProductId"
                                          [force]="formDirty"></ua-display-validation-errors>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </form>
</ng-container>
