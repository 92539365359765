import { Component, Inject, OnInit } from '@angular/core';
import { concatMap, take } from 'rxjs/operators';
import { BusyService } from '@unleashed/services/common';
import { APP_OPTIONS, AppOptions } from '@unleashed/common/config';
import { Booking, Item, NavigationSteps, ResourceTypeModifier } from '@unleashed/models/booking';
import { AddOnApiService } from '@unleashed/api/booking';
import { BookingSessionService } from '@unleashed/services/booking';

@Component({
  selector: 'ua-add-ons',
  templateUrl: './add-ons.component.html',
  styleUrls: ['./add-ons.component.scss']
})
export class AddOnsComponent implements OnInit {

  booking!: Booking;
  isValid = false;
  resourceTypeModifiers: ResourceTypeModifier[] = [];
  products: Item[] = [];
  selected = false;
  selectionChanged = false;
  addOnsLoaded = false;
  subtotal?: number;
  productServiceUrl: string;
  formDirty = false;
  error?: number;
  hasItemsToSelect?: boolean;

  constructor(
    private sessionService: BookingSessionService,
    private addOnApi: AddOnApiService,
    @Inject(APP_OPTIONS) appOptions: AppOptions,
    private busyService: BusyService
  ) {
    this.productServiceUrl = appOptions.productApiUrl;
  }

  ngOnInit(): void {
    this.sessionService.getBooking()
      .pipe(
        take(1),
        concatMap(booking => {
          this.booking = booking;
          return this.addOnApi.get(booking);
        }))
      .subscribe(addOns => {
        this.hasItemsToSelect = addOns.resourceTypeModifiers && addOns.resourceTypeModifiers.length > 0;
        addOns.products = addOns.products.filter(p => p.isActive);
        addOns.includedProducts = addOns.includedProducts.filter(p => p.isActive);
        addOns.products.forEach(p => {
          p.quantity = 0;
          p.imageUri = p.imageUri ? `${this.productServiceUrl}/products-service/products/${p.imageUri}` : `/assets/images/urbie.png`;
        });
        addOns.resourceTypeModifiers.forEach(r => {
          r.products = addOns.products.filter(p => p.productModifierTypeId === r.productModifierTypeId);
        });
        addOns.resourceTypeModifiers = addOns.resourceTypeModifiers.filter(r => r.products.length > 0);
        addOns.includedProducts.forEach(i => {
          const existsAsModifier =
            addOns.resourceTypeModifiers.findIndex(r => r.products.length === 1
              && ((r.products[0].parkProductId && r.products[0].parkProductId === i.parkProductId)
                || (r.products[0].ticketId && r.products[0].ticketId === i.ticketId)));

          const product = {
              id: i.id,
              parkProductName: i.parkProductName,
              parkProductId: i.parkProductId,
              ticketId: i.ticketId,
              imageUri: '',
              quantity: 0,
              price: 0,
              productModifierTypeId: i.productModifierTypeId,
              included: true,
              bundleScheduleId: i.bundleScheduleId,
            };
          if (existsAsModifier >= 0) {
            addOns.resourceTypeModifiers[existsAsModifier].quantity += i.quantity;
            addOns.resourceTypeModifiers[existsAsModifier].relatedBookingItems.push({...product, quantity: i.quantity});
          } else {
            addOns.resourceTypeModifiers.push({
              productModifierTypeId: i.parkProductId ?? i.ticketId ?? 0, // yes, this was intentional
              name: i.parkProductName,
              quantity: i.quantity,
              products: [product],
              relatedBookingItems: [{
                ...product,
                quantity: i.quantity
              }]
            });
          }
        });

        this.resourceTypeModifiers = addOns.resourceTypeModifiers;
        this.isValid = !this.hasItemsToSelect;
        this.products = addOns.products.filter(p => p.isPartyAddon);
        this.booking.bookingItems?.filter(i => !i.included)
          .forEach(i => {
            const product = this.products.find(p => p.parkProductId === i.parkProductId);
            if (product) {
              product.id = i.id;
              product.quantity = i.quantity;
            }
          });

        // Remove non-included items - they are always added back on navigation
        this.booking.bookingItems = this.booking.bookingItems?.filter(b => b.included);

        this.addOnsLoaded = true;
      }, err => this.error = err.status);

    this.sessionService.getSession()
      .subscribe(session => this.subtotal = session.subtotal);
  }

  back(): void {
    this.booking.step = 5;
    this.saveAndNavigate(this.booking, NavigationSteps.Attendees);
  }

  next(): void {
    this.booking.step = 6;
    this.saveAndNavigate(this.booking, NavigationSteps.Review);
  }

  updateBookingItems(event: Item[]): void {
    this.selectionChanged = true;
    this.booking.bookingItems = event;
  }

  updateIsValid(valid: boolean): void {
    this.isValid = valid;
  }

  remove(index: number): void {
    if (!this.subtotal || !this.products[index]?.price) {
      return;
    }

    this.selectionChanged = true;
    this.products[index].quantity--;
    this.subtotal -= this.products[index].price;
    this.sessionService.setSubtotal(this.subtotal);
  }

  add(index: number): void {
    if (!this.subtotal || !this.products[index]?.price) {
      return;
    }

    this.selectionChanged = true;
    this.products[index].quantity++;
    this.subtotal += this.products[index].price;
    this.sessionService.setSubtotal(this.subtotal);
  }

  saveAndNavigate(booking: Booking, step: NavigationSteps): void {

    this.formDirty = true;
    if (!this.isValid && step !== 'attendees') {
      return;
    }

    this.products.forEach(p => {
        if (p.quantity > 0) {
          this.booking.bookingItems?.push({
            id: p.id,
            included: false,
            parkProductName: p.parkProductName,
            quantity: p.quantity,
            productModifierTypeId: p.productModifierTypeId,
            parkProductId: p.parkProductId,
            price: p.price
          });
        }
      }
    );

    const nonSelectableIncluded = this.resourceTypeModifiers.filter(r => r.products.length === 1);
    if (this.booking.bookingItems.filter(i => i.included).length < nonSelectableIncluded.length) {
      this.selectionChanged = true;
      nonSelectableIncluded.forEach(r => {
        this.booking.bookingItems?.push({
          id: r.products[0].id,
          included: true,
          parkProductName: r.products[0].parkProductName,
          quantity: r.quantity,
          productModifierTypeId: r.productModifierTypeId,
          parkProductId: r.products[0].parkProductId,
          ticketId: r.products[0].ticketId,
          price: 0,
          bundleScheduleId: r.products[0].bundleScheduleId
        });
      });
    }

    if (!this.selectionChanged) {
      this.sessionService.navigateToStep(step);
      return;
    }

    this.busyService.set('addons', true);

    this.sessionService.upsertCart(booking)
      .subscribe(
        () => {
          this.busyService.set('addons', false);
          this.sessionService.navigateToStep(step);
        },
        err => {
          this.error = err.status;
          this.busyService.set('addons', false);
        });
  }

}
