import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'ua-card-pricing',
  templateUrl: './card-pricing.component.html',
  styleUrls: ['./card-pricing.component.scss']
})
export class CardPricingComponent {

  @Input() price?: number;
  @Input() quantity?: number;
  @Input() selected?: boolean;
  @Input() style: 'estimate' | 'exact' | 'showGuests' = 'estimate';
  @Input() disabled = false;
  @Input() showSign = false;
  @Input() imageUrl?: string;

  @Output() selectClicked: EventEmitter<void> = new EventEmitter();

  ngOnInit() {}

  clicked(): void {
    this.selectClicked.emit();
  }
}
