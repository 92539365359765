<div class="text-center my-2">
  <img src="/assets/logos/brand-logo-alt.svg" [alt]="brandName" height="125">
</div>

<div class="h4 text-primary text-center fw-bold">
  Find a Location
</div>

<div class="d-flex justify-content-center page outlet pt-0">
  <form [formGroup]="parkForm" (ngSubmit)="search(location.value)">
    <div class="input-group">
      <input type="text" formControlName="location" class="form-control" #location placeholder="Enter Zip Code or City" />
      <button type="submit" class="btn btn-primary input-group-append">Search</button>
    </div>
    <div class="text-center">
      <ua-display-validation-errors name='Location' [control]="parkForm.controls.location" [force]="formDirty">
      </ua-display-validation-errors>
    </div>
    <div class="mt-2" *ngIf="!searched || (filteredParks && filteredParks.length > 0)">
        <div class="mb-2" *ngFor="let park of filteredParks; let index = index; let last = last">
          <button type="button" class="ps-2 w-100 text-decoration-none btn btn-link text-start" [disabled]="!park.bookingDepositAmount" (click)="navigateToPark(park.urlSlug)">
            <div class="fs-5">{{ park.name | uppercase}}</div>
            <div class="text-secondary" *ngIf="!park.bookingDepositAmount">COMING SOON</div>
            <div class="text-secondary lh-1" *ngIf="park.address">
              {{ park.address.streetAddress }}<br>
              {{ park.address.city }} {{ park.address.state }} {{ park.address.zipCode }}
            </div>
          </button>
          <hr *ngIf="!last" class="my-2 bg-primary">
        </div>
    </div>
  </form>
</div>

<div class="h6 py-4 text-center page outlet" *ngIf="searched && filteredParks && filteredParks.length === 0"><p>Sorry, there are no locations open yet near your location. Check back soon!</p></div>

<div class="text-center">
  <a target="_blank" [href]="allLocationsLink" class="btn btn-link text-dark mb-4">View All Locations</a>
</div>
