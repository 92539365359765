import { Item } from './item';

export interface ResourceTypeModifier {
  productModifierTypeId: number;
  name: string;
  quantity: number;
  products: Item[];
  relatedBookingItems: Item[];
  selectedId?: number;
  scheduleDetailId?: number;
}
