import {Component, OnInit} from '@angular/core';
import { NavigationSteps } from '@unleashed/models/booking';
import { BookingSessionService } from '@unleashed/services/booking';

@Component({
  selector: 'ua-total',
  templateUrl: './total.component.html',
  styleUrls: ['./total.component.scss']
})
export class TotalComponent implements OnInit {

  subtotal = 0.00;
  bookingId?: string;
  step?: string;
  NavigationSteps = NavigationSteps;
  depositAmount = 0.00;

  constructor(private sessionService: BookingSessionService) {
  }

  ngOnInit(): void {
    this.sessionService.getSession()
      .subscribe(session => {
        this.subtotal = session.subtotal ?? 0.00;
        this.bookingId = session.bookingId;
        this.depositAmount = session.park?.bookingDepositAmount ?? 0.00;
        this.step = session.step;
      });
  }

}
