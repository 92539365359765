import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export function valueMatchValidator(form: FormGroup, validateControlName: string, compareControlName: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const validated = form.controls[validateControlName];
    const compareTo = form.controls[compareControlName];
    const bothTouched = validated.touched && compareTo.touched;
    const match = form.controls[validateControlName].value === form.controls[compareControlName].value;
    return bothTouched && !match
      ? {valueMismatch: {value: control.value}}
      : null;
  };
}
