import {Component, Inject, Injectable, OnInit} from '@angular/core';
import {APP_OPTIONS, AppOptions, BRAND_OPTIONS, BrandOptions} from '@unleashed/common/config';
import {BookingSessionService} from '@unleashed/services/booking';
import {BookingSession} from '@unleashed/models/booking';

@Injectable({
  providedIn: 'root'
})
@Component({
  selector: 'ua-salesforce-chat',
  templateUrl: './salesforce-chat.component.html',
  styleUrls: ['./salesforce-chat.component.scss']
})
export class SalesforceChatComponent implements OnInit {
  salesForceUrl: string;
  salesForceUrlJs: string;
  salesForceBaseLiveAgentUrl: string;
  salesForceBaseLiveAgentContentUrl: string;
  salesForceLiveAgentSetupFlowUrl: string;
  salesForceKey: string;
  showChat = false;
  enrolledGLT = false;
  scriptLoaded = false;
  parkName?: string;
  session?: BookingSession;
  recursiveCheckCount = 0;
  constructor(
    @Inject(APP_OPTIONS) appOptions: AppOptions,
    @Inject(BRAND_OPTIONS) brandOptions: BrandOptions,
    private sessionService: BookingSessionService,
  ) {
    this.salesForceUrl = appOptions.salesForceUrl;
    this.salesForceBaseLiveAgentUrl = appOptions.salesForceBaseLiveAgentUrl;
    this.salesForceBaseLiveAgentContentUrl = appOptions.salesForceBaseLiveAgentContentUrl;
    this.salesForceUrlJs = appOptions.salesForceUrlJs;
    this.salesForceLiveAgentSetupFlowUrl = appOptions.salesForceLiveAgentSetupFlowUrl;
    this.salesForceKey = appOptions.salesForceKey;
    this.showChat = brandOptions.showChat;
  }

  ngOnInit(): void {
    // if showChat is true, then load the library this will cause the chat button to appear
    if (this.showChat) {
      // handle setting the input location
      this.sessionService.getSession()
        .subscribe(session => {
          this.session = session;
          if (this.session.park) {
            this.parkName = this.session.park.name;
            this.enrolledGLT = this.session.park.enrolledGLT;
          }
          if (!this.scriptLoaded && this.enrolledGLT) {
            this.scriptLoaded = true;
            this.loadSalesForceScript();
          }
          // don't start recursive functions unless the script is loaded
          if (this.scriptLoaded) {
            this.handleSessionChange();
          }
        });
    }
  }

  loadSalesForceScript(): void {
    // create a script element
    const s = document.createElement('script');
    s.setAttribute('src', this.salesForceUrlJs);
    s.onload = () => {
      this.initESW('https://service.force.com');
      this.addClickEventToButton();
    };
    // append the script to the DOM
    document.body.appendChild(s);
  }

  initESW(gslbBaseURL: string): void {
    // @ts-ignore
    window.embedded_svc.settings.displayHelpButton = true; // Or false
    // @ts-ignore
    window.embedded_svc.settings.language = ''; // For example, enter 'en' or 'en-US'

    // @ts-ignore
    window.embedded_svc.settings.enabledFeatures = ['LiveAgent'];
    // @ts-ignore
    window.embedded_svc.settings.entryFeature = 'LiveAgent';
    // @ts-ignore
    embedded_svc.init(
      this.salesForceUrl,
      this.salesForceLiveAgentSetupFlowUrl,
      gslbBaseURL,
      this.salesForceKey,
      'Urban_Air_Chat_Agents',
      {
        baseLiveAgentContentURL: this.salesForceBaseLiveAgentContentUrl,
        deploymentId: '5725f000000DuwY',
        buttonId: '5735f000000Dvrn',
        baseLiveAgentURL: this.salesForceBaseLiveAgentUrl,
        eswLiveAgentDevName: 'Urban_Air_Chat_Agents',
        isOfflineSupportEnabled: true
      }
    );
  }

  // this function should only ever be called if showChat is true
  handleSessionChange(): void {
    // need to not create variables here because we don't want to recursively creat doc refs.
    if (document.querySelector<HTMLButtonElement>('.embeddedServiceHelpButton')) {
      const chatButton = document.querySelector<HTMLButtonElement>('.embeddedServiceHelpButton');
      if (chatButton) {
        // if the url is /parks or / then hide the chat button
        if (this.session?.park && this.enrolledGLT) {
          chatButton.style.display = 'block';
        } else {
          chatButton.style.display = 'none';
        }
      }
    } else {
      // recursively run this function until the button appears
      setTimeout(() => {
        this.handleSessionChange();
      }, 50);
    }
  }

  // the content for the button is not loaded until the button is clicked and the new javascript is downloaded and ran.
  // or the javascript it uses to create inputs inside the button. So we need to recursively check for the buttons contents
  addClickEventToButton(): void {
    // need to not create variables here because we don't want to recursively creat doc refs.
    if (document.querySelector<HTMLButtonElement>('.embeddedServiceHelpButton')) {
      const chatButton = document.querySelector<HTMLButtonElement>('.embeddedServiceHelpButton');
      if (chatButton) {
        chatButton.addEventListener('click', () => {
          this.handleSettingInputLocation();
          this.handleSetTypeQuestionType();
        });
      }
    } else {
      // recursively run this function until the button appears
      setTimeout(() => {
        this.addClickEventToButton();
      }, 50);
    }
  }

  // this function should only ever be called if showChat is true
  handleSettingInputLocation(): void {
    // find the input that is used to set the location in the chat we won't have a 
    // exact id match because the id is dynamic so we need to find the input that has the word location in it
    this.recursiveCheckCount++;
    document.querySelectorAll(".uiInput").forEach(el => {
      const inputLocation = el.querySelector('input')
      if(inputLocation && inputLocation.id.includes("Location") && this.parkName){
        inputLocation.value = this.parkName;
        inputLocation.dispatchEvent(new Event('change'));
        return;
      }
    }) 
    // if we can't find the input after 30 seconds of trying then log an error
    // this is a fail safe to prevent an infinite recursive loop we should never hit this,
    // but if we do then we need to know about it.
    if(this.recursiveCheckCount > 120){
      console.log("could not find input location")
      return;
    }
    // recursively run this function until the input appears
    setTimeout(() => {
      this.handleSettingInputLocation();
    }, 200);
  }

  handleSetTypeQuestionType(): void {
    // this is the input that is used to set the type of question in the chat
    if (document.querySelector<HTMLSelectElement>('#Type')) {
      const select = document.querySelector<HTMLSelectElement>('#Type');
      if (select) {
        select.value = 'Birthday Questions';
        select.dispatchEvent(new Event('change'));
      }
    } else {
      // recursively run this function until the input appears
      setTimeout(() => {
        this.handleSetTypeQuestionType();
      }, 200);
    }
  }
}
