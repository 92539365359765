<div class="modal" tabindex="-1" id="locationConfirmationModal" data-bs-backdrop="static" data-bs-keyboard="false">
  <div class="modal-dialog">
    <div class="modal-content border border-primary">
      <div class="modal-header bg-primary text-white">
        <h5 class="modal-title">Confirm Location</h5>
      </div>
      <div class="modal-body p-4">
        Please verify this is the location where you wish to book your party.
        <div class="container p-3">
          <div><strong>{{invoice?.park?.name}}</strong></div>
          <div>{{invoice?.park?.address?.streetAddress}}</div>
          <div>{{invoice?.park?.address?.city}}
            , {{invoice?.park?.address?.state}} {{invoice?.park?.address?.zipCode}}</div>
        </div>
        <div class="row gap-2 mt-4">
          <button type="button" class="col btn btn-block btn-primary" (click)="confirmLocation()">
            Yes
          </button>
          <button type="button" class="col btn btn-outline-primary btn-block" (click)="cancelLocation()"
                  data-bs-dismiss="modal">
            No
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal" tabindex="-1" id="confirmDecisionModal" data-bs-backdrop="static" data-bs-keyboard="false">
  <div class="modal-dialog">
    <div class="modal-content border border-primary">
      <div class="modal-header bg-primary text-white">
        <h5 class="modal-title">Confirm Location</h5>
      </div>
      <div class="modal-body p-4">
        <div class="py-2">
          Are you sure, as this will restart your booking process?
        </div>
        <div class="row gap-2 mt-4">
          <button type="button" class="col btn btn-block btn-primary" (click)="restartFromBeginning()">
            Yes
          </button>
          <button type="button" class="col btn btn-outline-primary btn-block" (click)="openLocationConfirmation()"
                  data-bs-dismiss="modal">
            No
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
