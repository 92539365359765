import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { Booking, TimeslotDate } from '@unleashed/models/booking';
import { BookingSessionService } from '@unleashed/services/booking';
import { TimeslotsApiService } from '@unleashed/api/booking';

@Component({
  selector: 'ua-timeslots-date',
  templateUrl: './timeslots-date.component.html',
  styleUrls: ['./timeslots-date.component.scss']
})
export class TimeslotsDateComponent implements OnInit {

  faCaretLeft = faCaretLeft;
  faCaretRight = faCaretRight;

  @Output() dateChanged = new EventEmitter<Date>();
  @Output() minPrice = new EventEmitter<number>();

  booking?: Booking;
  date: TimeslotDate = {date: new Date(), selected: true, price: 0};
  datesStore: BehaviorSubject<TimeslotDate[]> = new BehaviorSubject<TimeslotDate[]>([]);
  dates?: TimeslotDate[];
  middleDate: TimeslotDate = {date: new Date(), selected: true, price: 0};
  minDate?: Date;
  maxDate?: Date;
  minMiddleDate?: Date;
  maxMiddleDate?: Date;
  bookingLoaded = false;
  datesLoaded = false;
  configLoaded = false;

  constructor(
    private sessionService: BookingSessionService,
    private timeslotsApiService: TimeslotsApiService) {
  }

  ngOnInit(): void {
    this.sessionService.getBooking()
      .pipe(take(1))
      .subscribe(booking => {
        this.booking = booking;
        const date = booking.selectedDate ?? booking.preferredDate;
        this.date = {date, selected: true};
        this.middleDate = this.date;
        this.setNextDays(date);
        this.loadTimeslotDates(this.booking, date);
        this.bookingLoaded = true;
      });

    this.sessionService.getConfig()
      .subscribe(config => {
        const today = new Date();
        const year = today.getFullYear();
        const month = today.getMonth();
        const day = today.getDate();
        this.minMiddleDate = new Date(year, month, day + 4);
        this.maxMiddleDate = new Date(year, month, day + (config.maxAdvanceDays ?? 60) - 3);
        this.minDate = new Date(year, month, day + (config.minAdvanceDays ?? 2));
        this.maxDate = new Date(year, month, day + (config.maxAdvanceDays ?? 60) - 1);
        this.configLoaded = true;
      });

    this.datesStore.subscribe(dates => {
      this.dates = dates;
    });
  }

  loadTimeslotDates(booking: Booking, date: Date): void {
    this.timeslotsApiService.getTimeslotDates(booking)
      .subscribe(dates => {
        let minPrice = 9999;
        dates.forEach(timeslotDate => {
          timeslotDate.selected = timeslotDate.date.getTime() === date.getTime();
          if (timeslotDate?.price && timeslotDate?.price < minPrice) {
            minPrice = timeslotDate.price;
          }
        });
        this.datesLoaded = true;
        this.datesStore.next(dates);
        this.minPrice.emit(minPrice);
      });
  }

  setDate(date: TimeslotDate): void {
    this.date = {...date, selected: true};
    this.middleDate = this.date;
    this.dateChanged.emit(this.date.date);

    this.setDateRange(this.date);
  }

  setDateRange(timeslotDate: TimeslotDate): void {
    if (!this.booking) {
      return;
    }

    this.booking.selectedDate = timeslotDate.date;
    this.datesLoaded = false;
    this.setNextDays(timeslotDate.date);
    this.loadTimeslotDates(this.booking, this.date.date);
  }

  addFiveDays(): void {
    const middleDay = new Date(this.middleDate.date.getFullYear(), this.middleDate.date.getMonth(), this.middleDate.date.getDate() + 5);
    this.middleDate = {
      ...this.middleDate,
      date: middleDay,
      selected: true,
    };

    this.setDate(this.middleDate);
  }

  subtractFiveDays(): void {
    const middleDay = new Date(this.middleDate.date.getFullYear(), this.middleDate.date.getMonth(), this.middleDate.date.getDate() - 5);
    this.middleDate = {
      ...this.middleDate,
      date: middleDay,
      selected: true
    };

    this.setDate(this.middleDate);
  }

  setNextDays(date: Date): void {
    const behind2 = new Date(date);
    behind2.setDate(behind2.getDate() - 2);
    const behind1 = new Date(date);
    behind1.setDate(behind1.getDate() - 1);
    const ahead1 = new Date(date);
    ahead1.setDate(ahead1.getDate() + 1);
    const ahead2 = new Date(date);
    ahead2.setDate(ahead2.getDate() + 2);
    this.datesStore.next([
      {date: behind2},
      {date: behind1},
      {date: new Date(date), selected: date.getTime() === this.date.date.getTime()},
      {date: ahead1},
      {date: ahead2},
    ]);
  }

}
