import { Component, Inject, OnInit } from '@angular/core';
import { BookingSessionService } from '@unleashed/services/booking';
import { APP_OPTIONS, AppOptions, BRAND_OPTIONS, BrandOptions } from '@unleashed/common/config';

@Component({
  selector: 'ua-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {

  constructor(
    private sessionService: BookingSessionService
  ) {
  }

  ngOnInit(): void {
    this.sessionService.removeBooking();
    this.sessionService.removeSessionCart();
  }

}
