<div class="col d-flex justify-content-between">
  <div>
    <button *ngIf="showBack"
            class="btn btn-lg btn-link text-dark text-decoration-none fw-bold"
            (click)="back()"
            [disabled]="backDisabled">
      <fa-icon class="text-primary" [icon]="faCaretLeft" transform="grow-10"></fa-icon>
      <span class="ms-3">Back</span>
    </button>
  </div>
  <div>
    <button *ngIf="showNext"
            class="btn btn-lg btn-link text-dark text-decoration-none fw-bold"
            (click)="next()"
            [disabled]="nextDisabled">
      <span class="me-3">Next</span>
      <fa-icon class="text-primary" [icon]="faCaretRight" transform="grow-10"></fa-icon>
    </button>
  </div>
</div>
