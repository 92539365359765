import { Component, Input, OnInit } from '@angular/core';
import { Booking, PartyInvoice } from '@unleashed/models/booking';

@Component({
  selector: 'ua-party-time',
  templateUrl: './party-time.component.html',
  styleUrls: ['./party-time.component.scss']
})
export class PartyTimeComponent implements OnInit {

  @Input() booking?: Booking;
  @Input() color: 'primary' | 'secondary' | 'dark' = 'dark';
  @Input() align: 'start' | 'center' | 'end' = 'center';
  @Input() invoice?: PartyInvoice;

  cssClass = '';

  ngOnInit(): void {
    this.cssClass = `text-${this.color} text-${this.align}`;
  }

}
