import { Component, Inject, OnInit } from '@angular/core';
import { BRAND_OPTIONS, BrandOptions } from '@unleashed/common/config';
import { BookingSessionService } from '@unleashed/services/booking';
import { Observable, Subject, combineLatest } from 'rxjs';
import { Park } from '@unleashed/models/park';
import { map } from 'rxjs/operators';
import { PhoneNumberPipe } from '@unleashed/common/pipes';
import { Router, Event, NavigationEnd } from '@angular/router';

@Component({
  selector: 'ua-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})

export class FooterComponent implements OnInit {

  year = new Date().getFullYear();
  phoneNumber: string;
  park$?: Observable<Park | null | undefined>;
  url = new Subject<string>();
  phoneNumberPipe = new PhoneNumberPipe();

  constructor(@Inject(BRAND_OPTIONS) private brandOptions: BrandOptions,
    private sessionService: BookingSessionService, private router: Router) {
    this.phoneNumber = this.phoneNumberPipe.transform(brandOptions.phoneNumber);
  }

  ngOnInit(): void {
    this.park$ = this.sessionService.getSession().pipe(map(session => session.park));

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.url.next(event.url);
      }
    });

    combineLatest([this.park$, this.url]).subscribe(([park, url]) => {
      if (!park?.enrolledGLT && park?.phoneNumber && url !== '/parks') {
        this.phoneNumber = park?.phoneNumber ? this.phoneNumberPipe.transform(park?.phoneNumber) : this.brandOptions.phoneNumber;
      } else {
        this.phoneNumber = this.brandOptions.phoneNumber;
      }
    });

    // this is needed for the initial load
    this.url.next(location.pathname);
  }
}
