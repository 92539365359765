import { Attraction } from './attraction';

export interface Package {
  productLevelName: string;
  productLevelColor: string;
  productLevelDescription: string;
  productLevelId: number;
  ordinal: number;
  minCombinedPrice: number;
  quantity: number;
  attractions: Attraction[];
  selected: boolean;
  hasAttractions: boolean;
  maxRequiredHeight?: number;
}
