<ngx-skeleton-loader [theme]="{height: '56px'}"></ngx-skeleton-loader>
<ngx-skeleton-loader [theme]="{height: '37px'}"></ngx-skeleton-loader>
<ngx-skeleton-loader [count]="3" [theme]="{height: '19px'}"></ngx-skeleton-loader>
<hr class="my-2 bg-primary">
<div class="h4 fw-bold text-primary">
  Hangout:
</div>
<ngx-skeleton-loader [theme]="{height: '19px'}"></ngx-skeleton-loader>
<hr class="my-2 bg-primary">
<div class="h4 fw-bold text-primary">
  Included:
</div>
<ngx-skeleton-loader [count]="3" [theme]="{height: '19px'}"></ngx-skeleton-loader>
<hr class="my-2 bg-primary">
<ngx-skeleton-loader [count]="3" [theme]="{height: '19px'}"></ngx-skeleton-loader>
<hr class="my-2 bg-primary">
<ngx-skeleton-loader [theme]="{height: '48px'}"></ngx-skeleton-loader>
