<ng-container *ngIf="control && (control.touched || force)">

	<span class="small text-danger" *ngIf="control.errors?.required; else minLengthError" id="requiredError">
		{{ customMessage ? customMessage : name + ' is required' }}
	</span>

  <ng-template #minLengthError>
		<span class="small text-danger" *ngIf="control.errors?.minlength; else otherErrors" id="minLengthError">
			{{ customMessage ? customMessage : name + ' minimum length is ' + control.errors?.minlength.requiredLength }}
		</span>
  </ng-template>

  <ng-template #otherErrors>
		<span class="small text-danger" *ngIf="control.errors?.maxlength" id="maxLengthError">
			{{ customMessage ? customMessage : name + ' maximum length is ' + control.errors?.maxlength.requiredLength }}
		</span>
    <span class="small text-danger" *ngIf="control.errors?.invalidFormat || control.errors?.pattern"
          id="validationError">
			{{ customMessage ? customMessage : name + ' contains an invalid format' }}
		</span>
    <span class="small text-danger" *ngIf="control.errors?.max" id="maxError">
			{{ customMessage ? customMessage : name + ' maximum value is ' + control.errors?.max.max }}
		</span>
    <span class="small text-danger" *ngIf="control.errors?.min" id="minError">
			{{ customMessage ? customMessage : name + ' minimum value is ' + control.errors?.min.min }}
		</span>
    <span class="small text-danger" *ngIf="control.errors?.validValue" id="invalidError">
			{{ customMessage ? customMessage : name + ' has an invalid value' }}
		</span>
    <span class="small text-danger" *ngIf="control.errors?.invalidSelectOption" id="invalidSelectOption">
			{{ customMessage ? customMessage : name + ' has an invalid selection' }}
		</span>
    <span class="small text-danger" *ngIf="control.errors?.invalidDateFormat" id="invalidDateError">
			{{ customMessage ? customMessage : name + ' is invalid' }}
		</span>
    <span class="small text-danger" *ngIf="control.errors?.valueMismatch" id="mismatchError">
			{{ customMessage ? customMessage : name + ' do not match' }}
		</span>
    <span class="small text-danger" *ngIf="control.errors?.invalidEmail" id="emailError">
			{{ customMessage ? customMessage : 'Invalid email address format' }}
		</span>
    <span class="small text-danger" *ngIf="control.errors?.invalidPhone" id="phoneError">
			{{ customMessage ? customMessage : 'Invalid phone number' }}
		</span>
  </ng-template>

</ng-container>
