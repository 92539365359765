import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { combineLatest } from 'rxjs';
import { concatMap, take } from 'rxjs/operators';
import { Dropin } from 'braintree-web-drop-in';
import * as braintree from 'braintree-web-drop-in';
import { BusyService } from '@unleashed/services/common';
import { ClientTokenApiService } from '@unleashed/api/booking';
import { AuthorizationService } from '@unleashed/services/account';
import { BookingSessionService } from '@unleashed/services/booking';

@Component({
  selector: 'ua-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {

  @Output() dropinCreated = new EventEmitter<Dropin>();

  constructor(
    private clientTokenApi: ClientTokenApiService,
    private authService: AuthorizationService,
    private sessionService: BookingSessionService,
    private busyService: BusyService
  ) {
  }

  ngOnInit(): void {
    this.busyService.set('checkout', true);

    combineLatest([
      this.authService.getAccessToken()
        .pipe(
          take(1),
          concatMap(accessToken => {
            return this.clientTokenApi.get(accessToken);
          })
        ),
      this.sessionService.getConfig()
    ])
      .subscribe(([clientToken, config]) => {
        braintree
          .create({
            authorization: clientToken,
            container: '#dropin-container',
            threeDSecure: config.threeDSecureEnabled,
            dataCollector: true
          })
          .then(dropin => {
              this.dropinCreated.emit(dropin);
          })
          .finally(() => this.busyService.set('checkout', false));
      });
  }

}
