import { Inject, Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { concatMap, map, take } from 'rxjs/operators';
import { ApiService, ServiceBase } from '@unleashed/api/common';
import { APP_OPTIONS, AppOptions } from '@unleashed/common/config';
import {Booking, PartyInvoice} from '@unleashed/models/booking';
import { ApiHelpers } from '@unleashed/api/utils';
import { AuthorizationService } from '@unleashed/services/account';
import {Promotion} from '@unleashed/models/booking/promotion';

@Injectable({
  providedIn: 'root'
})
export class InvoiceApiService extends ServiceBase {

  subnav = 'bookings/invoices';

  constructor(
    @Inject(APP_OPTIONS) appOptions: AppOptions,
    private apiService: ApiService,
    private authService: AuthorizationService
  ) {
    super(appOptions.unleashedApiUrl);
  }

  getPreview(cookieId: string): Observable<PartyInvoice> {
    let params = new HttpParams();
    params = params.append('cookieId', cookieId);

    return this.apiService.get<PartyInvoice>(this.buildUrl('preview'), {params});
  }

  getInvoice(bookingId: string, accessToken: string): Observable<PartyInvoice> {
    return this.apiService.get<PartyInvoice>(this.buildUrl('confirmation', bookingId), {headers: {Authorization: `Bearer ${accessToken}`}})
      .pipe(map(invoice => {
        invoice.date = ApiHelpers.stripTimeZone(invoice.date);
        return invoice;
      }));
  }

  getInvoicesForAccount(brandId: number): Observable<Map<string, PartyInvoice[]>> {
    return this.authService.getAccessToken()
      .pipe(
        take(1),
        concatMap(token => {
          return this.apiService.get<PartyInvoice[]>(this.buildUrl(), {headers: {Authorization: `Bearer ${token}`}})
            .pipe(map(invoices => {
              invoices = invoices.filter(p => p.park.brandId === brandId);
              invoices.forEach(p => p.date = ApiHelpers.stripTimeZone(p.date));

              const partiesByPark = new Map<string, PartyInvoice[]>();
              invoices.forEach(p => {
                const parties = partiesByPark.get(p.park.name) ?? [];
                partiesByPark.set(p.park.name, [...parties, p]);
              });
              return partiesByPark;
            }));
        }));
  }
  confirmPromo(promoCode: string, cookieId: string, accountId?: number, getInvoice?: boolean): Observable<number | undefined> {
    const params = new HttpParams().set('promoCode', promoCode)
      .append('cookieId', cookieId)
      .append('getInvoice', getInvoice ?? false);

    if (accountId != null) {
      params.append('accountId', accountId ?? '');
    }

    return this.apiService.post<number | undefined>(this.buildUrl('confirm-promotion'), null, { params });
  }
  applyPromo(promoCode: string, cookieId: string, accountId?: number, getInvoice?: boolean): Observable<Promotion> {
    const params = new HttpParams().set('promoCode', promoCode)
      .append('cookieId', cookieId)
      .append('getInvoice', getInvoice ?? false)
      .append('isConfirmed', true.toString());

    if (accountId != null) {
      params.append('accountId', accountId ?? '');
    }

    return this.apiService.post<Promotion>(this.buildUrl('promotion'), null, { params });
  }
}
