import {Component, OnInit} from '@angular/core';
import {faMapMarkerAlt} from '@fortawesome/free-solid-svg-icons';
import { BookingSessionService } from '@unleashed/services/booking';
import { Park } from '@unleashed/models/park';

@Component({
  selector: 'ua-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss']
})
export class LocationComponent implements OnInit {

  faMapMarkerAlt = faMapMarkerAlt;
  park?: Park | null;

  constructor(private sessionService: BookingSessionService) {
  }

  ngOnInit(): void {
    this.sessionService.getSession()
      .subscribe(session => this.park = session.park);
  }
}
