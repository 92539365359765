import { Component, OnInit, ViewChild } from '@angular/core';
import { Modal } from 'bootstrap';
import { CountdownComponent, CountdownEvent } from 'ngx-countdown';
import { AuthorizationService } from '@unleashed/services/account';
import { Booking, NavigationSteps } from '@unleashed/models/booking';
import { BookingSessionService } from '@unleashed/services/booking';

@Component({
  selector: 'ua-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss']
})
export class TimerComponent implements OnInit {
  isTimeBegan: boolean | undefined = false;
  timeBegan?: Date;
  timeLeft?: number;
  timerClass?: string;
  booking?: Booking;
  sessionExpiredModal?: Modal;
  timeoutId?: number;
  @ViewChild('cd', { static: false }) private countdown?: CountdownComponent;

  constructor(
    private sessionService: BookingSessionService,
    private authService: AuthorizationService,
  ) { }

  ngOnInit(): void {
    const element = document.getElementById('sessionExpiredModal');
    if (element) {
      this.sessionExpiredModal = new Modal(element);
    }

    this.sessionService.getBooking().subscribe(booking => {
      this.isTimeBegan = booking.timeBegan ? true : false;
      this.timeBegan = booking.timeBegan;
      this.booking = booking;
      if (this.isTimeBegan && this.timeBegan) {
        this.setTimer(this.timeBegan);
      }
    });
  }

  showSessionExpiredModal(): void {
    this.sessionExpiredModal?.show();
  }

  closeModal(): void {
    this.sessionExpiredModal?.hide();
  }

  handleEvent(event: CountdownEvent): void {
    switch (event.action) {
      case 'done': {
        if (!this.timeBegan) {
          return;
        }
        const twentyFiveMinutesFromTimeBegan = new Date(this.timeBegan);
        twentyFiveMinutesFromTimeBegan.setMinutes(twentyFiveMinutesFromTimeBegan.getMinutes() + 25);
        if (twentyFiveMinutesFromTimeBegan.getTime() - new Date().getTime() > 0 && !this.booking?.emailAddress) {
          this.showSessionExpiredModal();
          this.timeoutId = window.setTimeout(() => {
            this.authService.logout();
            this.goToStart();
          }, twentyFiveMinutesFromTimeBegan.getTime() - new Date().getTime());
        } else {
          this.authService.logout();
          this.goToStart();
        }
        break;
      } case 'start':
        case 'notify': {
        let i = 0;
        let count = 0;
        for (i <= 0; i < 3; i ++) {
          setTimeout(() => {
            this.flashTimer(count);
            count ++;
          }, i * 2000);
        }
        break;
      }
    }
  }

  flashTimer(count: number): void {
    this.timerClass = 'text-primary';
    setTimeout(() => {
      this.timerClass = 'text-alt';
      if (count === 2) {
        this.timerClass = 'text-dark';
      }
    }, 1000);
  }

  setTimer(timeBegan: Date): void {
    const futureTimeFromTimeBegan = new Date(timeBegan);

    if (futureTimeFromTimeBegan.getTime() <= new Date().getTime() && !this.booking?.emailAddress) {
      futureTimeFromTimeBegan.setMinutes(futureTimeFromTimeBegan.getMinutes() + 20);
    }

    this.timeLeft = (futureTimeFromTimeBegan.getTime() - new Date().getTime()) / 1000;
    this.countdown?.restart();
  }

  goToStart(): void {
    this.closeModal();
    clearTimeout(this.timeoutId);
    this.sessionService.removeSessionCart();
    this.sessionService.navigateToStep(NavigationSteps.Start, true);
  }

  extendTime(): void {
    this.closeModal();
    clearTimeout(this.timeoutId);
    if (!this.booking) {
      return;
    }
    this.booking.timeBegan = new Date();
    this.timeBegan = this.booking.timeBegan;
    this.sessionService.upsertCart(this.booking).subscribe();
    this.setTimer(this.timeBegan);
  }

}
