import { ValidatorFn, AbstractControl } from '@angular/forms';

export function zipCodeFormatValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any; } | null => {

    const invalidFormat = {validValue: {value: control.value}};

    if (!control.value) {
      return invalidFormat;
    }

    const regex = new RegExp(/^(\d{5}(-\d{4})?|[A-Za-z]\d[A-Za-z] ?\d[A-Za-z]\d)$/gm);
    if (!regex.test(control.value)) {
      return invalidFormat;
    }

    const parts = control.value.split('-');

    if (parts[0].length > 7) {
      return invalidFormat;
    }

    if (parts.length === 2) {
      if (parts[1].length !== 4) {
        return invalidFormat;
      }
    }

    return null;
  };
}
