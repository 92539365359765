import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ua-crowd-meter',
  templateUrl: './crowd-meter.component.html',
  styleUrls: ['./crowd-meter.component.scss']
})
export class CrowdMeterComponent implements OnInit {

  level = '';

  private _levelId: number;
  @Input() set levelId(input: number) {
    this._levelId = input;
    this.level = this.getLevel(this._levelId);
  }
  get levelId(): number {
    return this._levelId;
  }

  constructor() {
    this._levelId = 0;
  }

  ngOnInit(): void { }

  private getLevel(levelId: number): string {
    switch (levelId) {
      case 1:
        return 'Low';
      case 2:
        return 'Medium';
      case 3:
        return 'High';
      default:
        return '';
    }
  }
}
