<div *ngIf="booking && !error" class="mt-4">

  <ua-nav-bar (backClicked)="back()" (nextClicked)="next()"></ua-nav-bar>

  <div class="text-center">
    <ua-call-to-action template="Choose additional items for {0} Party" [booking]="booking" [possessive]="true" [showTime]="true">
    </ua-call-to-action>
  </div>

  <ng-container *ngIf="resourceTypeModifiers">
    <ua-park-modifiers *ngIf="resourceTypeModifiers.length && addOnsLoaded"
                       [booking]="booking"
                       [modifiers]="resourceTypeModifiers"
                       [hasItemsToSelect]="hasItemsToSelect"
                       (bookingItems)="updateBookingItems($event)"
                       (formValid)="updateIsValid($event)"
                       [formDirty]="formDirty">
    </ua-park-modifiers>
  </ng-container>

  <h4 class="text-primary fw-bold mt-3">Additional Items:</h4>
  <ua-skeleton-card *ngIf="!addOnsLoaded"
                    [count]="3"
                    [descriptionHeight]="0"
                    [priceHeight]="32"
                    [hasImage]="true">
  </ua-skeleton-card>
  <ng-container *ngIf="addOnsLoaded">
    <div *ngFor="let product of products; let i = index">
      <div class="card mt-3 shadow border-primary border-2 rounded-5">
        <div class="card-body d-flex">
          <div>
            <div class="product-image">
              <img src="{{product.imageUri}}" class="rounded img-fluid float-start" onerror="this.style.display='none'"/>
            </div>
          </div>
          <div class="w-100 mx-3">
            <h5>{{product.parkProductName}}</h5>
            <div>{{product.description}}</div>
          </div>
          <div class="d-flex flex-column justify-content-between align-items-center">
            <div class="text-huge">
              +<ua-display-price [amount]="product.price"></ua-display-price>
            </div>
            <ua-quantity [loaded]="addOnsLoaded"
                         [count]="product.quantity"
                         [max]="product.maxQuantity"
                         (countDecreased)="remove(i)"
                         (countIncreased)="add(i)">
            </ua-quantity>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <div *ngIf="!products.length && addOnsLoaded" class="text-center mt-3">
    <h3>No additional items available</h3>
  </div>

  <div class="py-2">
    <ua-nav-bar (backClicked)="back()" (nextClicked)="next()"></ua-nav-bar>
  </div>

  <ua-start-over></ua-start-over>
</div>

<ua-error *ngIf="error" [errorCode]="error"></ua-error>
