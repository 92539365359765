<div class="page">
  <div class="sub-header w-100 bg-white">
    <div class="d-flex justify-content-between">
      <div class="ms-2 w-50">
          <ua-location></ua-location>
          <ua-timer></ua-timer>
      </div>
      <div class="me-2 w-50">
        <ua-total></ua-total>
      </div>
    </div>
  </div>
  <div class="outlet">
    <router-outlet *ngIf="navSet"></router-outlet>
  </div>
  <div class="modal fade" id="sessionExpiredModal" data-bs-backdrop="static">
    <div class="modal-dialog">
      <div class="modal-content border border-primary">
        <ua-alert
          message="Your session is about to expire. Would you like to extend your time?"
          header="Session Expiring"
          actionLabel="Extend Time"
          cancelLabel="No Thanks"
          (cancel)="goToStart()"
          (action)="extendTime()"></ua-alert>
      </div>
    </div>
  </div>
</div>
