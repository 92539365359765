<div class="text-center pricing d-flex flex-column justify-content-between h-100">
  <div>
    <img src="{{imageUrl}}" alt="Party Theme Image" *ngIf="imageUrl" class="w-100">
    <div class="text-tiny text-start" *ngIf="style === 'estimate'">starting at</div>
    <div class="m-0" [ngClass]="{'text-huge':!imageUrl, 'text-large':!!imageUrl}" *ngIf="(price ?? 0) === 0">
      Included<br>in Price
    </div>
    <div [ngClass]="{'text-huge':!imageUrl, 'text-large':!!imageUrl}" *ngIf="(price ?? 0) > 0">
      <ng-container *ngIf="showSign">
        +</ng-container>
      <ua-display-price [shortenNumber]="true" [amount]="price"></ua-display-price>
    </div>
    <div [ngClass]="{'text-huge':!imageUrl, 'text-large':!!imageUrl}" *ngIf="(price ?? 0) < 0">
      <ua-display-price [shortenNumber]="true" [amount]="price"></ua-display-price>
    </div>
    <div class="text-tiny text-end" *ngIf="style === 'estimate' || style === 'showGuests'">for {{quantity}} guests</div>
  </div>

  <button id="selectButton" class="btn btn-sm w-100"
    [class]="disabled ? 'btn-outline-dark' : selected ? 'btn-primary' : 'btn-outline-primary'"
    [ngClass]="(price ?? 0) === 0 ? 'mt-1' : 'mt-3'" (click)="clicked()" [disabled]="disabled">
    {{selected ? 'Selected' : 'Select'}}
  </button>
</div>