const decimalRegex: RegExp = new RegExp(/^\d*\.?\d{0,2}$/g);
const wholeRegex: RegExp = new RegExp(/^\d+$/g);

export function isValidNumber(event: KeyboardEvent, wholeNumber: boolean): boolean {
  const input = event.target as HTMLInputElement;
  const current: string = input.value;
  const positionStart = input.selectionStart;
  const positionEnd = input.selectionEnd;

  const next: string = [current.slice(0, positionStart ?? 0), event.key === 'Decimal' ? '.' : event.key, current.slice(positionEnd ?? 0)]
    .join('');
  if (next && !String(next).match(wholeNumber ? wholeRegex : decimalRegex)) {
    return false;
  }

  return true;
}

export function toInputDate(date?: Date, format: 'yyyy-mm-dd' | 'mm/dd/yyyy' = 'yyyy-mm-dd'): string | undefined {
  if (!date) {
    return undefined;
  }
  const month = `0${(date.getMonth() + 1)}`;
  const day = `0${(date.getDate())}`;
  switch (format) {
    case 'yyyy-mm-dd':
      return `${date.getFullYear()}-${month.slice(-2)}-${day.slice(-2)}`;
    case 'mm/dd/yyyy':
      return `${month.slice(-2)}/${day.slice(-2)}/${date.getFullYear()}`;
  }
}

export function getInputDate(text?: string): Date | undefined {
  if (!text || text === '' || (text.length !== 8 && text.length !== 10)) {
    return undefined;
  }

  let month = 0;
  let day = 0;
  let year = 0;

  if (text.length === 8) {
    month = +text.substring(0, 2);
    day = +text.substring(2, 4);
    year = +text.substring(4);
  } else {
    let split = '-';
    if (text.includes('/')) {
      split = '/';
    }

    const components = text.split(split);
    if (components.length !== 3) {
      return undefined;
    }

    month = +components[0];
    day = +components[1];
    year = +components[2];
  }

  return new Date(year, month - 1, day);
}

export function toInputTime(hours?: number): string {
  if (!hours) {
    return '';
  }
  const hour = `0${Math.floor(hours)}`;
  const minutes = `0${Math.round((hours % 1) * 60)}`;
  return `${hour.slice(-2)}:${minutes.slice(-2)}`;
}

export function getInputTime(text?: string): number | undefined {
  if (!text) {
    return undefined;
  }
  const components = text.split(':');
  switch (components.length) {
    case 0:
      return undefined;
    case 1:
      return +components[0];
    default:
      return +components[0] + (+components[1] / 60);
  }
}

export function dateFromInputString(input: string): Date | undefined {
  let month = 0;
  let day = 0;
  let year = 0;
  if (input.length === 8) {
    month = +input.substring(0, 2);
    day = +input.substring(2, 4);
    year = +input.substring(4);
  } else if (input.length === 10) {
    const values = input.split('/');
    if (values.length !== 3) {
      return  undefined;
    }
    month = +values[0];
    day = +values[1];
    year = +values[2];
  } else {
    return undefined;
  }

  return new Date(year, month - 1, day);
}

export function getInputNumber(text?: string): number | undefined {
  return text === '' ? undefined : text !== undefined ? +text : undefined;
}

export function getInputString(text?: string): string | undefined {
  return text === '' ? undefined : text;
}
