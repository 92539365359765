import { NgModule } from '@angular/core';
import {PhoneNumberPipe, PricePipe, TimePipe} from '@unleashed/common/pipes';

@NgModule({
  declarations: [
    TimePipe,
    PhoneNumberPipe,
    PricePipe
  ],
  imports: [
  ],
  exports: [
    TimePipe,
    PhoneNumberPipe,
    PricePipe
  ]
})
export class PipesModule { }
