import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  AddOnsComponent, AlertComponent, AttendeesComponent, CallToActionComponent, CardPricingComponent,
  CheckoutComponent, ConfirmationComponent, CrowdMeterComponent, HangoutsComponent, InvoiceComponent,
  InvoiceSkeletonComponent, LocationComponent, NavBarComponent, PackageInfoComponent, PackagesComponent, ParkModifiersComponent,
  PartyTimeComponent, PaymentComponent, QuantityComponent, ReviewComponent, SkeletonCardComponent, StartComponent, StartOverComponent,
  TimerComponent, TimeslotsComponent, TimeslotsDateComponent, TotalComponent, WrapperComponent
} from '@unleashed/booking/components';
import { CountdownModule } from 'ngx-countdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { DisplayValidationErrorsModule } from '@unleashed/common/components/display-validation-errors/display-validation-errors.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgxMaskModule } from 'ngx-mask';
import { NgxPrintModule } from 'ngx-print';
import { BookingRoutingModule } from '@unleashed/booking/booking-routing.module';
import { PipesModule } from '@unleashed/common/pipes/pipes.module';
import { DisplayErrorModule } from '@unleashed/common/components';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { LocationConfirmationComponent } from './components/location-confirmation/location-confirmation.component';
import { PromoConfirmationComponent } from './components/promo-confirmation/promo-confirmation.component';
import { DisplayPriceComponent } from './components/shared/display-price/display-price.component';
import { JustifiModule } from './components/justifi/JustifiModule';

@NgModule({
  declarations: [
    AddOnsComponent,
    AlertComponent,
    AttendeesComponent,
    CallToActionComponent,
    CardPricingComponent,
    CrowdMeterComponent,
    HangoutsComponent,
    LocationComponent,
    NavBarComponent,
    PackageInfoComponent,
    PackagesComponent,
    ParkModifiersComponent,
    QuantityComponent,
    ReviewComponent,
    SkeletonCardComponent,
    StartComponent,
    TimerComponent,
    TimeslotsComponent,
    TimeslotsDateComponent,
    TotalComponent,
    WrapperComponent,
    PartyTimeComponent,
    CheckoutComponent,
    PaymentComponent,
    ConfirmationComponent,
    StartOverComponent,
    InvoiceComponent,
    InvoiceSkeletonComponent,
    NotFoundComponent,
    LocationConfirmationComponent,
    PromoConfirmationComponent,
    DisplayPriceComponent
  ],
  imports: [
    AngularMyDatePickerModule,
    BookingRoutingModule,
    CommonModule,
    CountdownModule,
    DisplayValidationErrorsModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSkeletonLoaderModule,
    NgxMaskModule.forRoot(),
    NgxPrintModule,
    PipesModule,
    DisplayErrorModule,
    JustifiModule
  ]
})
export class BookingModule { }
