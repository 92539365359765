import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService, ServiceBase } from '@unleashed/api/common';
import { APP_OPTIONS, AppOptions } from '@unleashed/common/config';

@Injectable({
  providedIn: 'root'
})
export class ClientTokenApiService extends ServiceBase {

  subnav = 'payments/tokens';

  constructor(@Inject(APP_OPTIONS) appOptions: AppOptions, private apiService: ApiService) {
    super(appOptions.unleashedApiUrl);
  }

  get(accessToken: string): Observable<string> {
    return this.apiService.get<string>(this.buildUrl(), {headers: {Authorization: `Bearer ${accessToken}`}});
  }
}
