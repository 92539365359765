import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService, ServiceBase } from '@unleashed/api/common';
import { APP_OPTIONS, AppOptions } from '@unleashed/common/config';
import { Park } from '@unleashed/models/park';

@Injectable({
  providedIn: 'root'
})
export class ParksLocationService extends ServiceBase{

  subnav = 'parks-service/parks';

  constructor(
    protected api: ApiService,
    @Inject(APP_OPTIONS) appOptions: AppOptions
  ) {
    super(appOptions.parkApiUrl);
  }

  searchParksByZipcode(zipCode: string, radius: number, count: number, brandId: number): Observable<Park[]> {
    return this.api.get<Park[]>(this.buildUrl(`search?location=${zipCode}&distanceInMiles=${radius}&top=${count}&brandId=${brandId}`));
  }
}
