import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  AddOnsComponent, AttendeesComponent, CheckoutComponent, ConfirmationComponent,
  HangoutsComponent, PackagesComponent, ReviewComponent, StartComponent, TimeslotsComponent,
  WrapperComponent
} from '@unleashed/booking/components';
import { NotFoundComponent } from '@unleashed/booking/components/not-found/not-found.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'start' },
  {
    path: '',
    component: WrapperComponent,
    children: [
      { path: 'start', component: StartComponent },
      { path: 'packages', component: PackagesComponent },
      { path: 'timeslots', component: TimeslotsComponent },
      { path: 'hangouts', component: HangoutsComponent },
      { path: 'addons', component: AddOnsComponent },
      { path: 'attendees', component: AttendeesComponent },
      { path: 'review', component: ReviewComponent },
      { path: 'checkout', component: CheckoutComponent },
      { path: 'confirmation', component: ConfirmationComponent },
      { path: 'not-found', component: NotFoundComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BookingRoutingModule { }
