import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { BRAND_OPTIONS, BrandOptions } from '@unleashed/common/config';
import { BusyService } from '@unleashed/services/common';
import { ParksLocationService } from '@unleashed/api/park';
import { Park } from '@unleashed/models/park';
import { BookingSessionService } from '@unleashed/services/booking';

@Component({
  selector: 'ua-park-picker',
  templateUrl: './park-picker.component.html',
  styleUrls: ['./park-picker.component.scss']
})
export class ParkPickerComponent implements OnInit {

  brandId: number;
  brandName: string;
  allLocationsLink: string;
  filteredParks?: Park[];
  showFindLocation = false;
  parkForm: FormGroup;
  formDirty = false;
  searched = false;

  constructor(
    private parksLocationService: ParksLocationService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private busyService: BusyService,
    private sessionService: BookingSessionService,
    @Inject(BRAND_OPTIONS) brandOptions: BrandOptions,
  ) {
    this.brandId = brandOptions.brandId;
    this.brandName = brandOptions.name;
    this.allLocationsLink = brandOptions.allLocationsLink;
    this.parkForm = this.formBuilder.group({
      location: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(20)])
    });
  }

  ngOnInit(): void {
    this.sessionService.setPark(undefined);
  }

  displayFindLocation(): void {
    this.showFindLocation = true;
  }

  search(location: string): void {
    this.formDirty = true;
    if (!this.parkForm.valid || !this.parkForm.dirty) {
      return;
    }
    this.filteredParks = [];
    this.parkForm.markAsPristine();

    this.busyService.set('park-picker', true);
    this.parksLocationService.searchParksByZipcode(location, 30, 5, this.brandId).subscribe(parks => {
      this.filteredParks = parks.sort((a, b) => {
        if (a.bookingDepositAmount && b.bookingDepositAmount) {
          return 0;
        } else if (a.bookingDepositAmount) {
          return -1;
        } else {
          return 1;
        }
      });
      this.busyService.set('park-picker', false);
      this.searched = true;
    });
  }

  navigateToPark(url: string): void {
    if (!url) {
      return;
    }
    this.router.navigate([url], {relativeTo: this.route});
  }

}
