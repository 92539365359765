<div class="mt-4" *ngIf="!error">

  <ua-nav-bar (backClicked)="back()" (nextClicked)="next()"></ua-nav-bar>

  <ua-call-to-action class="text-center" template="Who will be Attending {0} Party" [booking]="booking"
    [possessive]="true" [showTime]="true">
  </ua-call-to-action>

  <div class="px-4 mt-3">
    <div class="row">
      <div class="col-8 d-flex flex-column">
        <h6 class="fw-bold mb-0">
          Number of Kids who Will {{attendeesText}}:
        </h6>
        <ngx-skeleton-loader *ngIf="!attendeesLoaded" [theme]="{height: '18px'}"></ngx-skeleton-loader>
        <p class="fst-italic disclaimer"
          *ngIf="attendeesLoaded && attendeeRestrictions?.participantPrice !== undefined">
          *Add more kids for <ua-display-price [amount]="attendeeRestrictions?.participantPrice"></ua-display-price> per person.
        </p>
      </div>
      <div class="col-4">
        <ua-quantity [loaded]="attendeesLoaded" [count]="participantCount" [min]="attendeeRestrictions?.minQuantity"
          [max]="attendeeRestrictions?.maxQuantity" [gtmTag]="'kid-participant-quantity'" (countDecreased)="subtractParticipant()"
          (countIncreased)="addParticipant()">
        </ua-quantity>
      </div>
    </div>

    <div class="row" *ngIf="attendeeRestrictions && participantCount >= attendeeRestrictions.maxQuantity">
      <p class="fst-italic text-secondary disclaimer" >
        <!-- PMA or The Little Gym -->
        You have reached the capacity limitation of your {{this.brandId === 3 || this.brandId === 4 
          ? 'party. Please call' 
          : 'selected hangout. Please try a different hangout, or call'}} <a class="phone-link" [href]="'tel:' + phoneNumber">{{phoneNumber}}</a> {{this.brandId === 3  || this.brandId === 4 
          ? 'for assistance in booking a larger party'
          : 'if you need further assistance'}}.
      </p>
    </div>
    <div class="row"
      *ngIf="attendeeRestrictions?.participantPrice && attendeeRestrictions?.adultParticipantPrice !== undefined">
      <div class="col-8 d-flex flex-column">
        <h6 class="fw-bold mb-0">
          Number of Parents who Will {{attendeesText}}:
        </h6>
        <ngx-skeleton-loader *ngIf="!attendeesLoaded" [theme]="{height: '18px'}"></ngx-skeleton-loader>
        <p class="fst-italic disclaimer"
          *ngIf="attendeesLoaded && attendeeRestrictions && attendeeRestrictions.adultParticipantPrice !== undefined">
          *Parents are
          <ua-display-price *ngIf="attendeeRestrictions.adultParticipantPrice
            && attendeeRestrictions.adultParticipantPrice > 0; else noAdultPrice" [amount]="attendeeRestrictions.adultParticipantPrice">
          </ua-display-price>
          <ng-template #noAdultPrice>50% off</ng-template>
          to play. Parents can watch kids play for free.
        </p>
      </div>
      <div class="col-4">
        <ua-quantity [loaded]="attendeesLoaded" [count]="adultParticipantCount"
          (countDecreased)="subtractAdultParticipant()" (countIncreased)="addAdultParticipant()"
          [max]="adultParticipantPrice ? undefined : 0" [gtmTag]="'adult-participant-quantity'">
        </ua-quantity>
      </div>
    </div>
  </div>

  <div class="py-2">
    <ua-nav-bar (backClicked)="back()" (nextClicked)="next()"></ua-nav-bar>
  </div>
  <ua-start-over></ua-start-over>
</div>

<ua-error *ngIf="error" [errorCode]="error"></ua-error>
