import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { throwError } from 'rxjs';
import { OAuthService, TokenResponse, OAuthSuccessEvent, OAuthErrorEvent } from 'angular-oauth2-oidc';
import { catchError, tap } from 'rxjs/operators';
import { CustomEncoder } from '@unleashed/api/common/custom-encoder';

@Injectable({
  providedIn: 'root'
})
export class UaOAuthService extends OAuthService {

  public fetchTokenUsingImpersonationToken(token: string, headers: HttpHeaders = new HttpHeaders()): Promise<TokenResponse> {
    if (!this.validateUrlForHttps(this.tokenEndpoint ?? '')) {
      throw new Error(
        'tokenEndpoint must use http, or config value for property requireHttps must allow http'
      );
    }

    token = token.replace(/\s/g, '+');

    const params = new HttpParams({ encoder: new CustomEncoder()})
      .set('grant_type', 'user_impersonation')
      .set('client_id', this.clientId ?? '')
      .set('client_secret', this.dummyClientSecret ?? '')
      .set('scope', this.scope ?? '')
      .set('token', token);

    return this.http.post<TokenResponse>(this.tokenEndpoint ?? '', params, { headers })
      .pipe(
        tap(response => {
          this.debug('tokenResponse', response);
          this.storeAccessTokenResponse(
            response.access_token,
            response.refresh_token,
            response.expires_in,
            response.scope
          );

          this.eventsSubject.next(new OAuthSuccessEvent('token_received'));
        }),
        catchError(err => {
          this.logger.error('Error performing impersonation flow', err);
          this.eventsSubject.next(new OAuthErrorEvent('token_error', err));

          return throwError(err);
        })
      )
      .toPromise();
  }
}
