import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

export function selectOptionValidator(values: number[] = [0], exclude: boolean = true): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const present = values.includes(control.value);
    return present && exclude || (!present && !exclude)
      ? {invalidSelectOption: {value: control.value}}
      : null;
  };
}
