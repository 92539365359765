export interface Hangout {
  resourceTypeId: number;
  name: string;
  description: string;
  price: number;
  displayPrice: number;
  available: number;
  amenities: string[];
  selected: boolean;
}
export interface HangoutMinimum extends Hangout {
  startHour?: number;
  endHour?: number;
  partyThemeId?: number;
}
export interface HangoutMinimumGrouped {
  [key: number]: HangoutMinimum;
}