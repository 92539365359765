import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'ua-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent {

  faCaretRight = faCaretRight;
  faCaretLeft = faCaretLeft;

  @Input() showBack = true;
  @Input() showNext = true;
  @Input() nextDisabled = false;
  @Input() backDisabled = false;

  @Output() backClicked: EventEmitter<void> = new EventEmitter();
  @Output() nextClicked: EventEmitter<void> = new EventEmitter();

  back(): void {
    this.backClicked.emit();
  }

  next(): void {
    this.nextClicked.emit();
  }
}
