import { Inject, Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService, ServiceBase } from '@unleashed/api/common';
import { APP_OPTIONS, AppOptions } from '@unleashed/common/config';
import { Booking, Timeslot, TimeslotDate } from '@unleashed/models/booking';
import { ApiHelpers } from '@unleashed/api/utils';

@Injectable({
  providedIn: 'root'
})
export class TimeslotsApiService extends ServiceBase {

  subnav = 'bookings/timeslots';

  constructor(@Inject(APP_OPTIONS) appOptions: AppOptions, private apiService: ApiService) {
    super(appOptions.unleashedApiUrl);
  }

  get(booking: Booking): Observable<Timeslot[]> {
    let params = new HttpParams();
    params = params.append('cookieId', booking.cookieId ?? '');
    params = params.append('parkId', booking.parkId);
    params = params.append('selectedDate', booking.selectedDate?.toISOString()
      ?? booking.preferredDate.toISOString());
    params = params.append('productLevelId', booking.productLevelId ?? 0);
    params = params.append('requiresHandicapAccessible', booking.requiresHandicap);

    return this.apiService.get<Timeslot[]>(this.buildUrl(''), {params});
  }

  getTimeslotDates(booking: Booking): Observable<TimeslotDate[]> {
    let params = new HttpParams();
    params = params.append('cookieId', booking.cookieId ?? '');
    params = params.append('parkId', booking.parkId);
    params = params.append('selectedDate', booking.selectedDate?.toISOString()
      ?? booking.preferredDate.toISOString());
    params = params.append('productLevelId', booking.productLevelId ?? 0);
    params = params.append('requiresHandicapAccessible', booking.requiresHandicap);

    return this.apiService.get<TimeslotDate[]>(this.buildUrl('dates'), {params})
      .pipe(
        map(timeslotDates => {
          timeslotDates.forEach(timeslotDate => timeslotDate.date = ApiHelpers.stripTimeZone(timeslotDate.date));
          return timeslotDates;
        })
      );
  }

}
