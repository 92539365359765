<ua-error *ngIf="error" [errorCode]="error"></ua-error>

<div *ngIf="configuration && !error" class="mt-4">
  <ua-nav-bar (nextClicked)="next()" [showBack]="false"></ua-nav-bar>
  <div class="row">
    <h1 class="text-primary">Book a Birthday Party!</h1>
    <sub><strong>Voted BEST Place for Birthday Parties</strong></sub>
  </div>

  <form class="mt-3" [formGroup]="bookingForm">

    <ng-container *ngFor="let gohFormGroup of guestsOfHonorFormGroups; let i=index">
      <div class="row mb-3">
        <div class="col-10" [formGroup]="gohFormGroup">
          <div>
            <label for="gohFirstName">Guest of Honor <span  *ngIf="showAsterisk" class="required-asterisk">*</span></label>
            <input id="gohFirstName" class="form-control" type="text" placeholder="Enter first name"
                   formControlName="firstName"/>
            <ua-display-validation-errors name='First name' [control]="gohFormGroup.controls.firstName"
                                          [force]="formDirty"></ua-display-validation-errors>
          </div>
          <div>
            <input id="gohLastName" class="form-control mt-2" type="text" placeholder="Enter last name"
                   formControlName="lastName"/>
            <ua-display-validation-errors name='Last name' [control]="gohFormGroup.controls.lastName"
                                          [force]="formDirty"></ua-display-validation-errors>
          </div>

          <label for="gohDateOfBirth" class="mt-3">Guest of Honor's Birthday <span  *ngIf="showAsterisk" class="required-asterisk">*</span></label>
          <div>
            <input id="gohDateOfBirth" class="form-control" mask="M0/d0/0000" [leadZeroDateTime]="true"
                   formControlName="dateOfBirth" placeholder="MM/DD/YYYY"/>
            <ua-display-validation-errors name='Birthday' [control]="gohFormGroup.controls.dateOfBirth"
                                          [force]="formDirty"></ua-display-validation-errors>
          </div>
        </div>
        <div class="col-2">
          <div class="col-2 mt-4">
            <Button *ngIf="this.guestsOfHonorCount < this.maxGuestsOfHonor && i === 0"
                    class="btn btn-link m-0" type="button"
                    (click)="addGuestOfHonorGroup()">
              <fa-icon [icon]="faPlus" transform="grow-5"></fa-icon>
            </Button>
            <Button *ngIf="i > 0 && i + 1 === this.guestsOfHonorCount" class="btn btn-link m-0"
                    type="button"
                    (click)="removeGuestOfHonorGroup()">
              <fa-icon [icon]="faMinus" transform="grow-5"></fa-icon>
            </Button>
          </div>
        </div>
      </div>
    </ng-container>

    <div class="row mb-2">
      <div class="col-10" [formGroup]="bookingForm">

        <label for="contactFirstName">Parent/Guardian's Name <span  *ngIf="showAsterisk" class="required-asterisk">*</span></label>
        <input id="contactFirstName" class="form-control" type="text" placeholder="Enter first name"
              formControlName="contactFirstName"/> 
              <ua-display-validation-errors name='First name' [control]="bookingForm.controls.contactFirstName"
                          [force]="formDirty"  *ngIf="(showAsterisk || pgReq)"></ua-display-validation-errors>
        <div class="mb-2">
          <input id="contactLastName" class="form-control mt-2" type="text" placeholder="Enter last name"
                formControlName="contactLastName"/>
          <ua-display-validation-errors name='Last name' [control]="bookingForm.controls.contactLastName"
                            [force]="formDirty" *ngIf="(showAsterisk || pgReq)"></ua-display-validation-errors>
        </div>

        <label for="phoneNumber">Parent/Guardian's Phone Number <span  *ngIf="showAsterisk" class="required-asterisk">*</span></label>
        <input id="phoneNumber" class="form-control" type="text" placeholder="Enter phone number"
              formControlName="phoneNumber"/>
        <ua-display-validation-errors name='Contact phone number' [control]="bookingForm.controls.phoneNumber"
                          [force]="formDirty"></ua-display-validation-errors>
      </div>
    </div>

    <div class="row">
      <div class="col-6 mb-3">

        <label for="preferredDate">Preferred Party Date <span  *ngIf="showAsterisk" class="required-asterisk">*</span></label>
        <div class="input-group">
          <input id="preferredDate" class="form-control" angular-mydatepicker
                 formControlName="preferredDate" [options]="preferredDateOptions"
                 #preferredDate="angular-mydatepicker" placeholder="Select a date"/>
          <button type="button" class="btn btn-primary" (click)="preferredDate.toggleCalendar()">
            <fa-icon [icon]="faCalendarAlt"></fa-icon>
          </button>
        </div>
        <ua-display-validation-errors name='Preferred Date' [control]="bookingForm.controls.preferredDate"
                                      [force]="formDirty"></ua-display-validation-errors>
      </div>
      <div class="mb-1 col-6">

        <label for="preferredTime">Preferred Party Time <span  *ngIf="showAsterisk" class="required-asterisk">*</span></label>
        <div *ngIf="isPreferredTimeDropdownRadio" class="container">
          <div class="form-check" *ngFor="let time of configuration.preferredTimes" >
            <input class="form-check-input" type="radio" formControlName="preferredTime" name="preferredTime" id="{{time.id}}" value="{{time.id}}" [checked]="time.id === 1" >
            <label class="form-check-label" for="{{time.id}}">
              {{time.timeOfDay}}
            </label>
          </div>
        </div>
        <select *ngIf="isPreferredTimeDropdownRadio === false" [class]="bookingForm.controls.preferredTime.value === 0 ? 'text-muted' : ''" id="preferredTime"
                class="form-select" formControlName="preferredTime">
          <option value="0" disabled>Choose one</option>
          <option class="text-dark" *ngFor="let time of configuration.preferredTimes" value="{{time.id}}">{{time.timeOfDay}}</option>
        </select>
        <ua-display-validation-errors name='Preferred Time' [control]="bookingForm.controls.preferredTime"
                                      [force]="formDirty"></ua-display-validation-errors>
      </div>
    </div>

    <div class="row g-3">
      <div class="col">
        Check if you require handicap accessible accommodations.
      </div>
      <div class="col-4 mb-3">
        <div class="form-check mt-2">
          <input type="checkbox" class="form-check-input form-check-input-lg" formControlName="requiresHandicap">
        </div>
      </div>
    </div>

    <div class="py-2">
      <ua-nav-bar (nextClicked)="next()" [showBack]="false"></ua-nav-bar>
    </div>
  </form>
</div>
