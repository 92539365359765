export interface Timeslot {
  startTime: number;
  endTime: number;
  price: number;
  quantity: number;
  available: number;
  selected?: boolean;
  baseBundleScheduleId: number;
  extraBundleScheduleId: number;
  adultBundleScheduleId: number;
  crowdLevelId: number;
  partyTheme: string;
  partyThemeId: number;
  partyThemeColorCode: string;
  partyThemeImageUrl: string;
  displayPrice?: number;
  isActive?: boolean;
}
