import {Component, Input} from '@angular/core';
import {AbstractControl} from '@angular/forms';

@Component({
  selector: 'ua-display-validation-errors',
  templateUrl: './display-validation-errors.component.html',
  styleUrls: ['./display-validation-errors.component.scss']
})
export class DisplayValidationErrorsComponent {

  @Input() control?: AbstractControl;
  @Input() name = 'Field';
  @Input() customMessage?: string;
  @Input() force = false;

  constructor() {
  }

}
