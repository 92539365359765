import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { concatMap, map, take } from 'rxjs/operators';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Modal } from 'bootstrap';
import { BusyService } from '@unleashed/services/common';
import { Attraction, Booking, NavigationSteps, Package } from '@unleashed/models/booking';
import { BookingSessionService } from '@unleashed/services/booking';
import { PackageApiService } from '@unleashed/api/booking';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
  selector: 'ua-packages',
  templateUrl: './packages.component.html',
  styleUrls: ['./packages.component.scss']
})
export class PackagesComponent implements OnInit {

  faInfoCircle = faInfoCircle;

  booking?: Booking;
  packages: Package[] = [];
  packageSelected = false;
  selectionChanged = false;
  showAsUpgrade = false;
  infoPackage?: Package;
  upgradePackage?: Package;
  upgradeAttractions: Attraction[] = [];
  availableAttractions: Attraction[] = [];
  infoUpgradeModal?: Modal;
  packagesLoaded = false;
  error?: number;

  constructor(
    private sessionService: BookingSessionService,
    private packageApiService: PackageApiService,
    private busyService: BusyService,
    private gtmService: GoogleTagManagerService
  ) {
  }

  ngOnInit(): void {
    const element = document.getElementById('infoModal');
    if (element) {
      this.infoUpgradeModal = new Modal(element);
    }

    this.sessionService.getBooking()
      .pipe(
        take(1),
        concatMap(booking => {
          this.booking = booking;
          this.sessionService.setSubtotal(booking.minSubtotal ?? 0)
          return this.loadPackages(booking);
        }))
      .subscribe(packages => {
        this.upgradePackage = this.packages[0];
        this.packages = packages;
        this.packagesLoaded = true;
      }, err => this.error = err.status);
  }

  loadPackages(booking: Booking): Observable<Package[]> {
    return this.packageApiService.get(booking)
      .pipe(map(packages => {
        // Set helper props for binding
        packages.forEach(p => {
          p.selected = false;
          p.hasAttractions = p.attractions.length > 0;
          const heights = p.attractions.map(a => a.requiredHeight ?? 0);
          if (heights && heights.length > 0) {
            p.maxRequiredHeight = Math.max(...heights);
          }
        });
        // Set selected state
        if (booking.productLevelId) {
          const selected = packages.find(p =>
            p.productLevelId === booking.productLevelId);
          if (selected) {
            selected.selected = true;
            this.packageSelected = true;
            if (!booking.selectedStartTime && selected.minCombinedPrice !== booking.minSubtotal) {
              this.sessionService.setSubtotal(selected.minCombinedPrice);
              this.selectionChanged = true;
            }
          }
        }
        return packages;
      }));
  }

  showModal(partyPackage: Package): void {
    this.infoPackage = partyPackage;
    this.upgradeAttractions = this.packages
      .filter(p => p.ordinal < partyPackage.ordinal)
      .map(p => p.attractions)
      .reduce((previous, current) => ([...previous, ...current]), []);
    this.availableAttractions = this.packages
      .filter(p => p.ordinal >= partyPackage.ordinal)
      .map(p => p.attractions)
      .reduce((previous, current) => ([...previous, ...current]), []);

    this.infoUpgradeModal?.show();
  }

  toggleSelection(index: number): void {
    this.selectionChanged = true;
    let subtotal = this.booking?.minSubtotal ?? 0;
    for (let i = 0; i < this.packages.length; i++) {

      if (i === index) {
        continue;
      }

      this.packages[i].selected = false;
    }

    this.packages[index].selected = !this.packages[index].selected;
    if (this.packages[index].selected && this.packages[index] !== this.packages[0]) {
      this.showAsUpgrade = true;
      this.showModal(this.packages[index]);
    }

    subtotal = this.packages[index].selected ? this.packages[index].minCombinedPrice : 0;
    this.sessionService.setSubtotal(subtotal);
    this.packageSelected = this.packages.find(p => p.selected) !== undefined;
  }

  closeModal(): void {
    this.infoUpgradeModal?.hide();
    this.showAsUpgrade = false;
  }

  upgrade(): void {
    this.toggleSelection(0);
    this.closeModal();
  }

  back(): void {
    if (!this.booking) {
      return;
    }
    this.booking.step = 1;
    this.saveAndNavigate(this.booking, NavigationSteps.Start);
  }

  next(): void {
    if (!this.booking) {
      return;
    }
    this.booking.step = 2;
    this.saveAndNavigate(this.booking, NavigationSteps.TimeSlots);
  }

  saveAndNavigate(booking: Booking, step: NavigationSteps): void {
    const selected = this.packages.find(t => t.selected);

    this.gtmService.pushTag({
    'event': 'packageSelectClicked',
    'selectedPackage': selected?.productLevelName
    });

    if (!this.selectionChanged) {
      this.sessionService.navigateToStep(step);
      return;
    }

    this.busyService.set('packages', true);

    booking.productLevelId = selected?.productLevelId;
    booking.minSubtotal = selected?.minCombinedPrice;

    booking.selectedDate = undefined;
    booking.selectedPreferredTimeId = undefined;
    booking.selectedStartTime = undefined;
    booking.selectedEndTime = undefined;
    booking.scheduleDetailId = undefined;
    booking.baseParticipantCount = undefined;
    booking.extraParticipantCount = undefined;
    booking.adultParticipantCount = undefined;
    booking.timeBegan = undefined;
    booking.bookingItems = [];
    booking.baseBundleScheduleId = undefined;
    booking.selectedResourcePrice = undefined;
    booking.selectedResourceTypeId = undefined;
    booking.adultBundleScheduleId = undefined;
    booking.extraBundleScheduleId = undefined;

    this.sessionService.setSubtotal(0);

    this.sessionService.upsertCart(booking)
      .subscribe(
        _ => {
          this.busyService.set('packages', false);
          this.sessionService.navigateToStep(step);
        },
        err => {
          this.error = err.status;
          this.busyService.set('packages', false);
        });
  }

}


