import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService, ServiceBase } from '@unleashed/api/common';
import { APP_OPTIONS, AppOptions } from '@unleashed/common/config';
import { Park } from '@unleashed/models/park';

@Injectable({
  providedIn: 'root'
})
export class ParksApiService extends ServiceBase {

  subnav = 'brands';

  constructor(@Inject(APP_OPTIONS) appOptions: AppOptions, private apiService: ApiService) {
    super(appOptions.unleashedApiUrl);
  }

  get(brandId: number): Observable<Park[]> {
    return this.apiService.get<Park[]>(this.buildUrl(brandId, 'parks'));
  }

}
