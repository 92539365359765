import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { Attraction, Booking, Package } from '@unleashed/models/booking';
import { BRAND_OPTIONS, BrandOptions } from '@unleashed/common/config';

@Component({
  selector: 'ua-package-info',
  templateUrl: './package-info.component.html',
  styleUrls: ['./package-info.component.scss']
})
export class PackageInfoComponent {

  @Input() booking?: Booking;
  @Input() upgradeAttractions: Attraction[] = [];
  @Input() availableAttractions: Attraction[] = [];
  @Input() upgradePackage?: Package;
  @Input() infoPackage?: Package;
  @Input() showAsUpgrade = false;

  brandId: number;
  productLevelUpsellText: string;

  @Output() dismissClicked = new EventEmitter<void>();
  @Output() upgradeClicked = new EventEmitter<void>();

  constructor(
    @Inject(BRAND_OPTIONS) brandOptions: BrandOptions
  ) {
    this.brandId = brandOptions.brandId;
    this.productLevelUpsellText = brandOptions.productLevelUpsellText;
  }

  dismiss(): void {
    this.dismissClicked.emit();
  }

  upgrade(): void {
    this.upgradeClicked.emit();
  }

}
