import { Component, Inject, OnInit } from '@angular/core';
import { Offcanvas } from 'bootstrap';
import { faBars, faCalendarDay, faSignOutAlt, faTimes, faUser } from '@fortawesome/free-solid-svg-icons';
import { APP_OPTIONS, AppOptions, BRAND_OPTIONS, BrandOptions } from '@unleashed/common/config';
import { User } from '@unleashed/models/account';
import { AuthorizationService } from '@unleashed/services/account';
import { NavigationSteps } from '@unleashed/models/booking';
import { BookingSessionService } from '@unleashed/services/booking';

@Component({
  selector: 'ua-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  faBars = faBars;
  faTimes = faTimes;
  faUser = faUser;
  faCalendarDay = faCalendarDay;
  faSignOutAlt = faSignOutAlt;

  brandId: number;
  brandName: string;
  storeUrl: string;
  privacyLink: string;
  termsLink: string;
  contactLink: string;
  specialEventRequestFormLink: string;
  familyPortalLink?: string;
  accountPortalLink?: string;
  faqLink: string;
  parkId?: string;
  parkSlug?: string;
  user: User = {loggedIn: false};
  authInProgress = false;
  showUserMenu = false;

  sidenav?: Offcanvas;

  constructor(
    private sessionService: BookingSessionService,
    @Inject(APP_OPTIONS) private appOptions: AppOptions,
    @Inject(BRAND_OPTIONS) private brandOptions: BrandOptions,
    private authService: AuthorizationService
  ) {
    this.brandId = brandOptions.brandId;
    this.brandName = brandOptions.name;
    this.privacyLink = brandOptions.privacyLink;
    this.termsLink = brandOptions.termsLink;
    this.contactLink = brandOptions.contactLink;
    this.specialEventRequestFormLink = brandOptions.specialEventRequestFormLink;
    this.faqLink = brandOptions.faqLink;
    this.storeUrl = appOptions.storeUrl;
    this.familyPortalLink = appOptions.familyPortalUrl;
    this.accountPortalLink = appOptions.accountPortalUrl;
  }

  ngOnInit(): void {
    const element = document.getElementById('sidenav');
    if (element) {
      this.sidenav = new Offcanvas(element);
    }

    this.sessionService.getSession()
      .subscribe(session => {
        this.parkSlug = session.park?.urlSlug;
        this.parkId = session.park?.id;
      });

    this.authService.getUser()
      .subscribe(user => this.user = user);

    this.authService.getInProgress()
      .subscribe(ip => this.authInProgress = ip);
  }

  login(): void {
    this.sessionService.navigateToLogin(NavigationSteps.Start);
  }

  accountInfo(): void {
    this.toggleUserMenu();
    this.sessionService.navigateToAccountInfo();
  }

  reservations(): void {
    this.toggleUserMenu();
    this.sessionService.navigateToReservations();
  }

  toggleSideNav(event: Event): void {
    this.sidenav?.toggle(event.target as HTMLElement);
  }

  logout(): void {
    this.sessionService.removeCart();
    this.sessionService.removeBooking();
    this.authService.logout();
    this.toggleUserMenu();
    this.sessionService.navigateToStep(NavigationSteps.Start);
  }

  toggleUserMenu(): void {
    this.showUserMenu = !this.showUserMenu;
  }

}
