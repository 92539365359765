import { Component, Input } from '@angular/core';

@Component({
  selector: 'ua-skeleton-card',
  templateUrl: './skeleton-card.component.html',
  styleUrls: ['./skeleton-card.component.scss']
})
export class SkeletonCardComponent {

  cards: number[] = [0];
  descriptionHeightString = '72px';
  priceHeightString = '68px';
  priceWidthString = '88px';

  @Input('count') set count(count: number) {
    this.cards = Array(count).fill(0);
  }

  @Input('descriptionHeight') set descriptionHeight(pixels: number) {
    this.descriptionHeightString = `${pixels}px`;
  }

  @Input('priceHeight') set priceHeight(pixels: number) {
    this.priceHeightString = `${pixels}px`;
  }

  @Input('priceWidth') set priceWidth(pixels: number) {
    this.priceWidthString = `${pixels}px`;
  }


  @Input() hasImage = false;

}
