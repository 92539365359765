import { HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APP_OPTIONS, AppOptions } from '@unleashed/common/config';
import { ApiService, ServiceBase } from '@unleashed/api/common';
import { AttendeeRestrictions, Booking } from '@unleashed/models/booking';

@Injectable({
  providedIn: 'root'
})
export class AttendeeApiService extends ServiceBase {

  subnav = 'bookings/attendees';

  constructor(@Inject(APP_OPTIONS) appOptions: AppOptions, private apiService: ApiService) {
    super(appOptions.unleashedApiUrl);
  }

  getAttendeeRestrictions(booking: Booking): Observable<AttendeeRestrictions> {
    let params = new HttpParams();
    params = params.append('baseBundleScheduleId', booking.baseBundleScheduleId ?? 0)
      .append('extraBundleScheduleId', booking.extraBundleScheduleId ?? 0)
      .append('adultBundleScheduleId', booking.adultBundleScheduleId ?? 0)
      .append('scheduleDetailId', booking.scheduleDetailId ?? 0);

    return this.apiService.get<AttendeeRestrictions>(this.buildUrl('restrictions'), { params });
  }

}
