import { Inject, Injectable } from '@angular/core';
import { APP_OPTIONS, AppOptions } from '@unleashed/common/config';
import { OAuthStorage } from 'angular-oauth2-oidc';
import { CookieService } from 'ngx-cookie-service';

interface CookieOptions {
  namespace: string;
  domain: string;
  duration: number;
  secure: boolean;
}

@Injectable()
export class AuthStorageService extends OAuthStorage {

  localAuthKey = 'AUTH_COOKIE_STORAGE';
  cookieOptions: CookieOptions;

  constructor(
    private cookieService: CookieService,
    @Inject(APP_OPTIONS) private appOptions: AppOptions
  ) {
    super();
    this.cookieOptions = appOptions.auth.cookieOptions;
  }

  getItem(key: string): string {
    return this.cookieService.get(this.formatKey(key));
  }

  removeItem(key: string): void {
    this.cookieService.delete(this.formatKey(key), '/', this.cookieOptions.domain);
  }

  setItem(key: string, data: string): void {
    this.cookieService.set(
      this.formatKey(key), data, new Date(Date.now() + this.cookieOptions.duration),
      '/',
      this.cookieOptions.domain,
      this.cookieOptions.secure);
  }

  formatKey(key: string): string {
    return `${this.cookieOptions.namespace || ''}_${this.localAuthKey}_${key}`;
  }
}
