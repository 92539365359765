<div class="modal-header bg-alt">
  <p class="text-primary fw-bold fs-5 lh-1" *ngIf="!showAsUpgrade && brandId === 1">
    Urban Air will provide a bottle of water, Urban Air
    Socks and a party scratch off for
    each paid party guest.
  </p>

  <div *ngIf="showAsUpgrade">
    <ua-call-to-action [template]="productLevelUpsellText + (upgradeAttractions && upgradeAttractions.length ?  ' Would you like to upgrade now to get access to:' : ' Would you like to upgrade now?')"
                       [booking]="booking" [groupText]="'They'" [possessive]="false"
                       class="text-primary">
    </ua-call-to-action>

    <ul class="text-primary fw-bold mx-4" *ngIf="upgradeAttractions && upgradeAttractions.length">
      <li *ngFor="let attraction of upgradeAttractions"><span [ngClass]="{'text-light': brandId === 4, 'text-dark': brandId !== 4}">{{attraction.name}}</span></li>
    </ul>

    <h6 *ngIf="upgradePackage && upgradePackage.maxRequiredHeight" class="text-primary text-center mt-4 fw-bold">
      Best for {{upgradePackage.maxRequiredHeight}}" or taller
    </h6>

    <div *ngIf="showAsUpgrade" class="text-center">
      <button class="btn btn-primary btn-sm mt-3" (click)="upgrade()">Upgrade Now</button>
    </div>
  </div>
</div>
<div class="modal-body">
  <div>
    <h1 *ngIf="infoPackage && !showAsUpgrade" class="mx-4" [style.color]="infoPackage.productLevelColor">
      {{infoPackage.productLevelName}}
    </h1>

    <h6 *ngIf="showAsUpgrade && availableAttractions && availableAttractions.length" class="text-primary text-center mx-4 fw-bold">
      Included with the current package:
    </h6>

    <ul *ngIf="!showAsUpgrade && upgradeAttractions && upgradeAttractions.length" class="text-dim fw-bold mx-4 my-0">
      <li *ngFor="let attraction of upgradeAttractions"><span>{{attraction.name}}</span></li>
    </ul>
    <ul class="text-primary fw-bold mx-4" *ngIf="availableAttractions && availableAttractions.length">
      <li *ngFor="let attraction of availableAttractions"><span class="text-dark">{{attraction.name}}</span></li>
    </ul>

    <h6 *ngIf="infoPackage && infoPackage.maxRequiredHeight" class="text-primary text-center mt-4 fw-bold">
      Best for {{infoPackage.maxRequiredHeight}}" or taller
    </h6>

    <div class="text-center">
      <button class="btn btn-sm mt-2" [ngClass]="showAsUpgrade ? 'btn-link text-muted' : 'btn-primary'"
              (click)="dismiss()">{{showAsUpgrade ? 'No Thanks' : 'Dismiss'}}
      </button>
    </div>
  </div>
</div>
