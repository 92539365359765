<div *ngIf="!error; else showError">
  <div *ngIf="booking" class="mt-4">
    <ua-nav-bar (backClicked)="back()" (nextClicked)="next()" [nextDisabled]="!packageSelected"></ua-nav-bar>

    <ua-call-to-action class="text-center"
                       template="Choose a package for {0} Party"
                       [booking]="booking"
                       [possessive]="true">

    </ua-call-to-action>
  </div>

  <div class="mt-3">
    <ua-skeleton-card *ngIf="!packages.length && !packagesLoaded" [count]="4"></ua-skeleton-card>
    <div *ngIf="!packages.length && packagesLoaded" class="text-center">
      <h3>Sorry, no packages available for the selected date</h3>
    </div>

    <div *ngFor="let partyPackage of packages; let i = index;">
      <div class="card mt-3 shadow border-2 rounded-5" [style.border-color]="partyPackage.productLevelColor">
        <div class="card-body d-flex">
          <div class="d-flex flex-column w-100 me-3">
            <div class="h2 fw-bold" [style.color]="partyPackage.productLevelColor"> {{ partyPackage.productLevelName }}
              <sup *ngIf="partyPackage.hasAttractions">
                <button (click)="showModal(partyPackage)" class="btn btn-link btn-sm p-0">
                  <fa-icon [icon]="faInfoCircle"></fa-icon>
                </button>
              </sup>
            </div>
            <p>{{partyPackage.productLevelDescription}}</p>
          </div>
          <ua-card-pricing class="ms-auto"
                           [price]="partyPackage.minCombinedPrice"
                           [quantity]="partyPackage.quantity"
                           [selected]="partyPackage.selected"
                           (selectClicked)="toggleSelection(i)">
          </ua-card-pricing>
        </div>
      </div>
    </div>
  </div>

  <div class="py-2">
    <ua-nav-bar (backClicked)="back()" (nextClicked)="next()" [nextDisabled]="!packageSelected"></ua-nav-bar>
  </div>

  <ua-start-over></ua-start-over>
</div>

<ng-template #showError>
  <ua-error *ngIf="error" [errorCode]="error"></ua-error>
</ng-template>

<div class="modal fade" id="infoModal" data-bs-backdrop="static" data-bs-keyboard="false">
  <div class="modal-dialog">
    <div class="modal-content">
      <ua-package-info [booking]="booking"
                       [upgradeAttractions]="upgradeAttractions"
                       [availableAttractions]="availableAttractions"
                       [infoPackage]="infoPackage"
                       [upgradePackage]="upgradePackage"
                       [showAsUpgrade]="showAsUpgrade"
                       (dismissClicked)="closeModal()"
                       (upgradeClicked)="upgrade()">
      </ua-package-info>
    </div>
  </div>
</div>
