import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';
import { getInputDate } from '@unleashed/common/utils';

export function maxDateValidator(maxDate: Date): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const date = getInputDate(control.value);

    return !date || date > maxDate
      ? {invalidDateFormat: {value: control.value}}
      : null;
  };
}

export function minDateValidator(minDate: Date): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const date = getInputDate(control.value);

    return !date || date < minDate
      ? {invalidDateFormat: {value: control.value}}
      : null;
  };
}
