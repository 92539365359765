export enum NavigationSteps {
  AddOns = 'addons',
  Attendees = 'attendees',
  Checkout = 'checkout',
  Confirmation = 'confirmation',
  Hangouts = 'hangouts',
  Packages = 'packages',
  Parks = 'parks',
  Review = 'review',
  Start = 'start',
  TimeSlots = 'timeslots',
  NotFound = 'not-found'
}
