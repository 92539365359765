import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BusyService {

  busyMap = new Map<string, boolean>();
  busyState = new BehaviorSubject<boolean>(false);

  set(key: string, busy: boolean): void {
    this.busyMap.set(key, busy);

    let nextState = busy;
    if (!busy) {
      const otherBusy = Array.from(this.busyMap.values()).find(b => b);
      nextState = otherBusy ?? false;
    }

    if (this.busyState.value !== nextState) {
      this.busyState.next(nextState);
    }
  }

  get(): Observable<boolean> {
    return this.busyState.asObservable();
  }

  clear(): void {
    this.busyMap.clear();
    this.busyState.next(false);
  }
}
