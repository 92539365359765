import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { DisplayValidationErrorsModule } from '../display-validation-errors/display-validation-errors.module';
import { LoginFormComponent } from './components';

@NgModule({
  declarations: [
    LoginFormComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DisplayValidationErrorsModule
  ],
  exports: [
    LoginFormComponent
  ]
})
export class AccountLoginModule { }
