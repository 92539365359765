import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ua-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {

  @Input() header?: string;
  @Input() message?: string;
  @Input() cancelLabel?: string;
  @Input() actionLabel?: string;
  @Output() action: EventEmitter<void> = new EventEmitter<void>();
  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  ngOnInit(): void {
  }

  alertAct(): void {
    this.action.emit();
  }

  alertCancel(): void {
    this.cancel.emit();
  }

}
