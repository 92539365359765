import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService, ServiceBase } from '@unleashed/api/common';
import { APP_OPTIONS, AppOptions } from '@unleashed/common/config';
import { State } from '@unleashed/models/address';

@Injectable({
  providedIn: 'root'
})
export class StatesApiService extends ServiceBase {

  subnav = 'addresses/states';

  constructor(
    @Inject(APP_OPTIONS) appOptions: AppOptions,
    private apiService: ApiService
  ) {
    super(appOptions.unleashedApiUrl);
  }

  get(): Observable<State[]> {
    return this.apiService.get<State[]>(this.buildUrl());
  }

}
