import { Inject, Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { APP_OPTIONS, AppOptions } from '@unleashed/common/config';
import { ApiService, ServiceBase } from '@unleashed/api/common';
import { AddOns, Booking } from '@unleashed/models/booking';

@Injectable({
  providedIn: 'root'
})
export class AddOnApiService extends ServiceBase {

  subnav = 'bookings/addons';

  constructor(@Inject(APP_OPTIONS) appOptions: AppOptions, private apiService: ApiService) {
    super(appOptions.unleashedApiUrl);
  }

  get(booking: Booking): Observable<AddOns> {
    let params = new HttpParams();
    params = params.append('cookieId', booking.cookieId ?? '');
    params = params.append('parkId', booking.parkId ?? '');

    return this.apiService.get<AddOns>(`${this.baseUrl}/${this.subnav}`, {params});
  }
}
