import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import { PagedResult, Success } from '@unleashed/models/api';

export interface HttpRequestOptions {
  headers?: HttpHeaders | {
    [header: string]: string | string[];
  };
  body?: any;
  observe?: 'body';
  params?: HttpParams | {
    [param: string]: string | string[];
  };
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
}

const UaAuthenticatedWith = 'Ua-Authenticated-With';
const UrbanAirBearer = 'urban-air-bearer';

@Injectable({providedIn: 'root'})
export class ApiService {

  constructor(public http: HttpClient) {
  }

  getFile(path: string): Observable<Blob> {
    return this.http.get(path, {responseType: 'blob'});
  }

  get<TResponse>(path: string, options?: HttpRequestOptions): Observable<TResponse> {
    if (options?.headers !== undefined && options?.headers !== null) {
      options.headers = {...options.headers, [UaAuthenticatedWith]: UrbanAirBearer};
    }
    return this.wrapRequest(this.http.get<Success<TResponse>>(path, options));
  }

  getPagedData<TResponse>(path: string, options?: HttpRequestOptions): Observable<PagedResult<TResponse>> {
    return this.http.get<PagedResult<TResponse>>(path, options);
  }

  delete<TResponse, TBody = Partial<TResponse>>(
    path: string,
    body?: TBody,
    options?: HttpRequestOptions): Observable<TResponse> {
    return this.wrapRequest<TResponse>(
      this.http.request<Success<TResponse>>('delete', path, {body, params: options ? options.params : undefined})
    );
  }

  post<TResponse, TBody = Partial<TResponse>>(
    path: string,
    body: TBody | null,
    options?: HttpRequestOptions): Observable<TResponse> {
    if (options?.headers !== undefined && options?.headers !== null) {
      options.headers = {...options.headers, [UaAuthenticatedWith]: UrbanAirBearer};
    }
    return this.wrapRequest<TResponse>(
      this.http.post<Success<TResponse>>(path, body, options)
    );
  }

  put<TResponse, TBody = TResponse>(
    path: string,
    body: TBody | null,
    options?: HttpRequestOptions): Observable<TResponse> {
    if (options?.headers !== undefined && options?.headers !== null) {
      options.headers = {...options.headers, [UaAuthenticatedWith]: UrbanAirBearer};
    }
    return this.wrapRequest<TResponse>(
      this.http.put<Success<TResponse>>(path, body, options)
    );
  }

  private wrapRequest<TResponse>(request: Observable<Success<TResponse>>): Observable<TResponse> {
    return request.pipe(
      map(response => {
        return response?.data as TResponse;
      }));
  }
}
