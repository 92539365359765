
export const buildUrl = (...args: (string | number | boolean)[]) => args.join('/');

export abstract class ServiceBase {
  subnav = '';
  serviceName = '';

  constructor(public baseUrl: string) {
  }

  buildUrl(...args: (string | number | boolean)[]): string {
    return buildUrl(this.baseUrl, this.subnav, ...args);
  }

}
