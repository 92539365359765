import {Component, EventEmitter, Input, OnInit, OnChanges, Output, SimpleChanges} from '@angular/core';
import { Modal } from 'bootstrap';
import { BookingSessionService } from '@unleashed/services/booking';

@Component({
  selector: 'ua-promo-confirmation',
  templateUrl: './promo-confirmation.component.html',
})
export class PromoConfirmationComponent implements OnInit, OnChanges {
  promoConfirmationModal?: Modal;
  @Input() promoConfirmMessage = '';
  @Input() triggerPromoConfirmationPopup = false;
  @Output() confirmPromo = new EventEmitter();
  @Output() cancelPromo = new EventEmitter();
  constructor(private sessionService: BookingSessionService) {}

  ngOnInit(): void {
    const elementPromoConfirmation = document.getElementById('promoConfirmationModal');
    if (elementPromoConfirmation) {
      this.promoConfirmationModal = new Modal(elementPromoConfirmation);
    }
    this.sessionService.bookingPromoMessage?.subscribe((value) => {
      this.promoConfirmMessage = value;
    });
    // this handles the case when the user logs in after applying a promo code
    // state is lost because the component is destroyed and recreated other wise
    this.sessionService.bookingPromoModalTrigger?.subscribe((value) => {
      if (value) {
        this.triggerPromoConfirmationPopup = false;
        this.promoConfirmationModal?.show();
      } else {
        this.promoConfirmationModal?.hide();
      }
    });
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.triggerPromoConfirmationPopup &&
      changes.triggerPromoConfirmationPopup.currentValue
    ) {
      this.promoConfirmationModal?.show();
    }
  }
  confirm(): void {
    this.sessionService.triggerBookingPromoModal(false);
    this.confirmPromo.emit();
  }
  cancel(): void {
    this.sessionService.triggerBookingPromoModal(false);
    this.cancelPromo?.emit();
  }
}
