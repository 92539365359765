import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { JustifiWrapperComponent } from './justifi.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
@NgModule({
  declarations: [JustifiWrapperComponent],
   imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule
  ],
  exports: [JustifiWrapperComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class JustifiModule {}
