import { Park } from '@unleashed/models/park';
export interface PartyInvoice {
  bookingId: number;
  partyTheme: string;
  date: Date;
  startHour: number;
  endHour: number;
  guests: PartyInvoiceGuest[];
  productLevelColor: string;
  productLevelName: string;
  packagePrice: number;
  additionalParticipantPrice: number;
  additionalAdultParticipantPrice: number;
  totalParticipantQuantity: number;
  baseParticipantQuantity: number;
  adultParticipantQuantity: number;
  resourceTypeName: string;
  resourcePrice: number;
  included: PartyInvoiceProduct[];
  fees: PartyInvoiceFee[];
  promo: PartyInvoicePromotion;
  amenities: string[];
  addons: PartyInvoiceProduct[];
  subtotal: number;
  tax: number;
  deposit: number;
  confirmation: string;
  total: number;
  approvalCode: string;
  isSpecialEvent?: boolean;
  organizationName?: string;
  park: Park;
  promoDiscount?: number;
  entitlementName?: string;
  entitlementDiscount?: number;
  paymentProcessorVersion?: number;
}

export interface PartyInvoiceProduct {
  name: string;
  quantity: number;
  totalPrice: number;
}
export interface PartyInvoiceFee {
  name: string;
  quantity: number;
  price: number;
}
export interface PartyInvoicePromotion {
  code?: string;
  price?: number;
}
export interface PartyInvoiceGuest {
  firstName: string;
  lastName: string;
}
