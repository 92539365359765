import {HttpClient, HttpParams} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { APP_OPTIONS, AppOptions } from '@unleashed/common/config';
import { ApiService } from '@unleashed/api/common';
import { ApiHelpers } from '@unleashed/api/utils';
import { Booking, WaiverBooking } from '@unleashed/models/booking';

@Injectable({
  providedIn: 'root'
})
export class BookingApiService {

  baseUrl: string;

  constructor(
    public http: HttpClient,
    private apiService: ApiService,
    @Inject(APP_OPTIONS) appOptions: AppOptions) {
    this.baseUrl = `${appOptions.bookingApiUrl}/booking-service`;
  }

  createCart(booking: Booking): Observable<Booking> {
    return this.apiService.post<Booking>(`${this.baseUrl}/carts`, booking)
      .pipe(map(b => {
        return ApiHelpers.fixUpBookingDates(b);
      }));
  }

  deleteCart(cookieId: string): void {
    this.apiService.delete(`${this.baseUrl}/carts/${cookieId}`)
      .subscribe();
  }

  getBooking(cookieId: string): Observable<Booking> {
    return this.apiService.get<Booking>(`${this.baseUrl}/carts/${cookieId}`)
      .pipe(map(booking => {
        return  ApiHelpers.fixUpBookingDates(booking);
      }));
  }

  getWaiverBooking(externalId: string, bookingId?: string): Observable<WaiverBooking> {
    let params = new HttpParams();
    if (bookingId) {
      params = params.append('bookingId', bookingId);
    }
    return this.apiService.get<WaiverBooking>(`${this.baseUrl}/bookings/${externalId}`, {params});
  }
}
