<div class="d-flex">
  <button class='btn btn-link'
          [disabled]="!loaded || !allowDecrease"
          (click)="decreaseCount()"
  >
    <fa-icon [icon]="faMinus"
             [ngClass]="allowDecrease ? 'text-primary' : 'text-secondary'">
    </fa-icon>
  </button>

  <div class="quantity align-self-center">
    <ngx-skeleton-loader *ngIf="!loaded" [theme]="{height: '33px', margin: '10px 0 0 0'}"></ngx-skeleton-loader>
    <h1 class="text-center mb-0" [ngClass]="gtmTag ?? ''" *ngIf="loaded">{{count}}</h1>
  </div>

  <button class='btn btn-link'
          [disabled]="!loaded || !allowIncrease"
          (click)="increaseCount()">
    <fa-icon [icon]="faPlus"
             [ngClass]="allowIncrease ? 'text-primary' : 'text-secondary'">
    </fa-icon>
  </button>
</div>
