import { NgModule } from '@angular/core';
import { EcommerceComponent } from '@unleashed/ecommerce/ecommerce.component';
import { FooterComponent, HeaderComponent, ParkPickerComponent, SpinnerComponent } from '@unleashed/ecommerce/components';
import { BrowserModule } from '@angular/platform-browser';
import { EcommerceRoutingModule } from '@unleashed/ecommerce/ecommerce-routing.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HttpClientModule } from '@angular/common/http';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { ReactiveFormsModule } from '@angular/forms';
import { DisplayValidationErrorsModule } from '@unleashed/common/components/display-validation-errors/display-validation-errors.module';
import { NgxPrintModule } from 'ngx-print';
import { BookingModule } from '@unleashed/booking/booking.module';
import { AuthStorageService } from '@unleashed/services/account/auth-storage.service';
import { SalesforceChatComponent } from './components/salesforce-chat/salesforce-chat.component';

@NgModule({
  declarations: [
    EcommerceComponent,
    HeaderComponent,
    FooterComponent,
    ParkPickerComponent,
    SpinnerComponent,
    SalesforceChatComponent
  ],
  imports: [
    BrowserModule,
    EcommerceRoutingModule,
    FontAwesomeModule,
    HttpClientModule,
    OAuthModule.forRoot(),
    ReactiveFormsModule,
    DisplayValidationErrorsModule,
    NgxPrintModule,
    BookingModule
  ],
  exports: [
    EcommerceComponent,
    HeaderComponent,
    FooterComponent,
    ParkPickerComponent,
    SpinnerComponent
  ],
  providers: [
    {provide: OAuthStorage, useClass: AuthStorageService}
  ],
})
export class EcommerceModule { }
