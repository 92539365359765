<div class="d-flex justify-content-between text-primary">
  <div class="d-flex align-self-center">
    <button class="btn btn-sm btn-link" [disabled]="!datesLoaded || (minMiddleDate && middleDate.date.getTime() <= minMiddleDate.getTime())" (click)="subtractFiveDays()">
      <fa-icon class="fa-lg" [icon]="faCaretLeft" transform="grow-5"></fa-icon>
    </button>
  </div>
  <div class="col-10">
    <ngx-skeleton-loader *ngIf="!bookingLoaded" [theme]="{height: '40px'}"></ngx-skeleton-loader>
    <ul class="nav nav-tabs justify-content-center border-color-primary">
      <li class="nav-item text-center col align-self-end" *ngFor="let d of dates">
        <button *ngIf="!d.selected" [ngClass]="minDate && maxDate && (d.date.getTime() < minDate.getTime() || d.date.getTime() > maxDate.getTime()) ? 'disabled text-secondary' : 'text-dark'" class="nav-link p-1 w-100" (click)="setDate(d)">
          <strong>
            <div class="text-wrap">
              {{d.date | date: 'E' }} {{d.date | date: 'M/d' }}
            </div>
            <div *ngIf="d.price && minDate && maxDate && d.date.getTime() >= minDate.getTime() && d.date.getTime() <= maxDate.getTime(); else noPrice" class="text-success">
              <ua-display-price [amount]="d.price"></ua-display-price>
            </div>
          </strong>
        </button>
        <span *ngIf="d.selected" class="nav-link text-dark p-1 active border-color-primary">
          <strong>
            <div class="text-wrap">
              {{d.date | date: 'E' }} {{d.date | date: 'M/d' }}
            </div>
          </strong>
        </span>
      </li>
    </ul>
  </div>
  <div class="d-flex align-self-center">
    <button class="btn btn-sm btn-link" [disabled]="!datesLoaded || (maxMiddleDate && middleDate.date.getTime() >= maxMiddleDate.getTime())" (click)="addFiveDays()">
      <fa-icon class="fa-lg" [icon]="faCaretRight" transform="grow-5"></fa-icon>
    </button>
  </div>
</div>


<ng-template #noPrice>
  <ngx-skeleton-loader *ngIf="!datesLoaded" [theme]="{height: '15px', margin: '0 0 -4px 0'}"></ngx-skeleton-loader>
  <div *ngIf="datesLoaded" class="text-secondary">
    N/A
  </div>
</ng-template>
