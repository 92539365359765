import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService, ServiceBase } from '@unleashed/api/common';
import { APP_OPTIONS, AppOptions } from '@unleashed/common/config';
import { Payment } from '@unleashed/models/booking/payment';
import { PartyInvoice } from '@unleashed/models/booking';
import { ApiHelpers } from '@unleashed/api/utils';
import { map } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})

export class BookingInvoiceApiService extends ServiceBase {
  constructor(
    @Inject(APP_OPTIONS) appOptions: AppOptions,
    private apiService: ApiService
  ) {
    super(appOptions.unleashedApiUrl);
  }

  processCartDeposit(paymentOriginId: number, brandId: number, accessToken: string,
                     payment: Payment, parkId?: string, cookieId?: string): Observable<string> {
    return this.apiService.post<string, Payment>(
      `${this.baseUrl}/origins/${paymentOriginId}/channels/1/brands/${brandId}/parks/${parkId}/bookings/carts/${cookieId}`,
      payment,
      {headers: {Authorization: `Bearer ${accessToken}`}}
    );
  }

  processSpecialEventDeposit(paymentOriginId: number, brandId: number, accessToken: string,
                             payment: Payment, parkId?: string, cookieId?: string): Observable<string> {
    return this.apiService.post<string, Payment>(
      `${this.baseUrl}/origins/${paymentOriginId}/channels/1/brands/${brandId}/parks/${parkId}/bookings/special-events/${cookieId}`,
      payment,
      {headers: {Authorization: `Bearer ${accessToken}`}}
    );
  }

  getInvoice(bookingId: string, accessToken: string, brandId: number, parkId?: string): Observable<PartyInvoice> {
    return this.apiService.get<PartyInvoice>( `${this.baseUrl}/origins/1/channels/1/brands/${brandId}/parks/${parkId}/bookings/confirmation/${bookingId}`, { headers: { Authorization: `Bearer ${accessToken}` } })
      .pipe(map(invoice => {
        invoice.date = ApiHelpers.stripTimeZone(invoice.date);
        return invoice;
      }));
  }

  getPreview(cookieId: string, brandId: number, parkId?: string): Observable<PartyInvoice> {
    let params = new HttpParams();
    params = params.append('cookieId', cookieId);
    return this.apiService.get<PartyInvoice>( `${this.baseUrl}/origins/1/channels/1/brands/${brandId}/parks/${parkId}/bookings/preview`,
      {params});
  }
}
