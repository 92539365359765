<header>
  <nav class="fixed-top bg-alt">
    <div class="page d-flex justify-content-end navbar">
      <button class="btn btn-link text-tertiary" (click)="toggleSideNav($event)" *ngIf="!user.impersonated">
        <fa-icon [icon]="faBars" transform="grow-10"></fa-icon>
      </button>
      <div class="ms-1 flex-grow-1">
        <a *ngIf="!authInProgress && parkId" routerLink=".">
          <img src="/assets/logos/brand-logo.svg" [alt]="brandName" height="50">
        </a>
      </div>
      <button *ngIf="!user.loggedIn && !authInProgress && !user.impersonated"
              class="btn btn-link text-decoration-none text-tertiary"
              (click)="login()">
        Log In
      </button>
      <button *ngIf="user.loggedIn && !user.impersonated" class="btn btn-link text-tertiary dropdown-toggle"
              (click)="toggleUserMenu()">
        <fa-icon [icon]="faUser" transform="grow-10 text-tertiary"></fa-icon>
      </button>
    </div>
    <div class="user-menu w-100 bg-primary" *ngIf="showUserMenu && !user.impersonated">
      <ul class="nav flex-column">
        <li class="nav-item text-light nav-link">
          Hello, {{user.firstName}}
        </li>
        <li class="nav-item">
          <button class="btn btn-link text-light text-decoration-none" (click)="reservations()">
            <fa-icon [icon]="faCalendarDay" class="me-3"></fa-icon>
            Reservations
          </button>
        </li>
        <li class="nav-item">
          <button class="btn btn-link text-light text-decoration-none" (click)="accountInfo()">
            <fa-icon [icon]="faUser" class="me-3"></fa-icon>
            Account Info
          </button>
        </li>
        <li class="nav-item">
          <button class="btn btn-link text-light text-decoration-none" (click)="logout()">
            <fa-icon [icon]="faSignOutAlt" class="me-3"></fa-icon>
            Log Out
          </button>
        </li>
      </ul>
    </div>
  </nav>
</header>

<div class="offcanvas offcanvas-start bg-primary side-bar" tabindex="-1" id="sidenav">
  <div class="offcanvas-header justify-content-end">
    <button type="button" class="btn btn-link text-light" (click)="toggleSideNav($event)">
      <fa-icon [icon]="faTimes" transform="grow-10"></fa-icon>
    </button>
  </div>
  <div class="offcanvas-body">
    <ul class="nav flex-column">
      <li class="nav-item" *ngIf="familyPortalLink">
        <a class="nav-link text-light btn-lg" [href]="brandId === 1 ? accountPortalLink : familyPortalLink">
          Family Portal
        </a>
      </li>
      <li class="nav-item" *ngIf="specialEventRequestFormLink">
        <a class="nav-link text-light btn-lg" [href]="specialEventRequestFormLink"
           target="_blank">Special Event Request Form</a>
      </li>
      <li class="nav-item" *ngIf="parkId && parkSlug">
        <a class="nav-link text-light btn-lg"
           href="{{storeUrl}}/{{brandId === 1 ? parkId : parkSlug}}">
          Online Store
        </a>
      </li>
      <li class="nav-item" *ngIf="faqLink">
        <a class="nav-link text-light btn-lg"
           [href]="faqLink" target="_blank">Frequently Asked Questions</a>
      </li>
      <li class="nav-item" *ngIf="privacyLink">
        <a class="nav-link text-light btn-lg" [href]="privacyLink"
           target="_blank">Privacy Policy</a>
      </li>
      <li class="nav-item" *ngIf="termsLink">
        <a class="nav-link text-light btn-lg" [href]="termsLink"
           target="_blank">Terms of Use</a>
      </li>
      <li class="nav-item" *ngIf="contactLink">
        <a class="nav-link text-light btn-lg" [href]="contactLink"
           target="_blank">Contact Us
        </a>
      </li>
    </ul>
  </div>
</div>
