import { HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService, ServiceBase } from '@unleashed/api/common';
import { APP_OPTIONS, AppOptions } from '@unleashed/common/config';
import { Booking, Hangout, HangoutMinimum } from '@unleashed/models/booking';
import { ApiHelpers } from '@unleashed/api/utils';

@Injectable({
  providedIn: 'root'
})
export class HangoutApiService extends ServiceBase {

  subnav = 'bookings/hangouts';

  constructor(@Inject(APP_OPTIONS) appOptions: AppOptions, private apiService: ApiService) {
    super(appOptions.unleashedApiUrl);
  }
  
  getHangoutsMinimum(booking: Booking): Observable<HangoutMinimum[]> {
    let params = new HttpParams();
    params = params.append('cookieId', booking.cookieId ?? '');
    params = params.append('parkId', booking.parkId);
    params = params.append('selectedDate', booking.selectedDate?.toISOString()
      ?? booking.preferredDate.toISOString());
    params = params.append('productLevelId', booking.productLevelId ?? 0);
    params = params.append('requiresHandicap', booking.requiresHandicap);

    return this.apiService.get<HangoutMinimum[]>(this.buildUrl('hangouts-minimum'), { params });
  }

  getHangouts(booking: Booking): Observable<Hangout[]> {
    let params = new HttpParams();
    params = params.append('cookieId', booking.cookieId ?? '');
    params = params.append('parkId', booking.parkId);
    params = params.append('productLevelId', booking.productLevelId ?? 0);
    params = params.append('selectedDate', booking.selectedDate?.toISOString() ?? '');
    params = params.append('startHour', booking.selectedStartTime ?? 0);
    params = params.append('endHour', booking.selectedEndTime ?? 0);
    params = params.append('requiresHandicap', booking.requiresHandicap);

    return this.apiService.get<Hangout[]>(this.buildUrl(), { params });
  }

  confirmHangout(booking: Booking): Observable<Booking> {
    return this.apiService.post<Booking>(this.buildUrl('confirm'), booking)
      .pipe(map(b => {
        return ApiHelpers.fixUpBookingDates(b);
      }));
  }

}
