import { BundleSchedule } from './bundleSchedule';
import { Booking } from './booking';
import { Resource } from './resource';
import { ParkResourceTypeAmenity } from './parkResourceTypeAmenity';
import { Park } from '@unleashed/models/park';

export interface BookingDetail extends Booking {
  productLevelName: string;
  productLevelColor: string;
  resource: Resource;
  park: Park;
  depositAmount: number;
  costOfAdults: number;
  costOfAdditionalParticipants: number;
  subtotal: number;
  tax?: number;
  adultBundleSchedule: BundleSchedule;
  participantBundleSchedule: BundleSchedule;
  partyBundleSchedule: BundleSchedule;
  parkResourceTypeAmenities: ParkResourceTypeAmenity[];
  partyTheme: string;
}
