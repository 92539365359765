import { DayOfWeek } from '@unleashed/models/api';

export function now(): Date {
  return new Date(Date.now());
}

export function today(): Date {
  const date = now();
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date;
}

export function getMonthString(date: Date): string {
  const year = date.getFullYear();
  const month = `0${date.getMonth() + 1}`.slice(-2);
  return `${year}-${month}`;
}

export function getDateFromMonthString(yearMonth: string): Date | undefined {
  const parts = yearMonth.split('-');
  if (parts.length !== 2) {
    return undefined;
  }
  // type="month" input sends actual month number back
  // so we need to subtract 1
  return new Date(+parts[0], +parts[1] - 1, 1);
}

export function getDateFromString(date: string): Date | undefined {
  const parts = date.split('-');
  if (parts.length !== 3) {
    return undefined;
  }

  return new Date(+parts[0], +parts[1] - 1, +parts[2]);
}

export function isDateOnlyMatch(date1: Date, date2: Date): boolean {
  return date1.getFullYear() === date2.getFullYear()
    && date1.getMonth() === date2.getMonth()
    && date1.getDate() === date2.getDate();
}

export function isBetweenDates(date: Date, start: Date, end: Date): boolean {
  const time = date.getTime();
  return time >= start.getTime() && time <= end.getTime();
}

export function isOnDayOfWeek(date: Date, dow: number): boolean {
  const flag = getFlag(date.getDay());
  if (!flag) {
    return false;
  }
  // tslint:disable-next-line:no-bitwise
  return (dow & flag) === flag;
}

export function getLocalDateString(date?: Date): string | undefined {
  if (!date) {
    return undefined;
  }
  return new Date(date.getTime() - date.getTimezoneOffset() * 60000)
    .toISOString()
    .split('T')[0];
}

export function getFirstDateOfWeek(date?: Date): Date | undefined {
  if (!date) {
    return undefined;
  }

  date = new Date(date);
  return new Date(date.setDate(date.getDate() - date.getDay()));
}

export function getLastDateOfWeek(date?: Date): Date | undefined {
  if (!date) {
    return undefined;
  }

  date = new Date(date);
  return new Date(date.setDate(date.getDate() - date.getDay() + 6));
}

export function isOnSameWeek(date1: Date, date2: Date): boolean {
  const startDate = getFirstDateOfWeek(date1);
  const endDate = getLastDateOfWeek(date1);
  if (!startDate || !endDate) {
    return false;
  }
  return isBetweenDates(date2, startDate, endDate);
}

export function getDaysOfWeek(dayMask: number): string {
  let dowString = '';
  // tslint:disable-next-line:no-bitwise
  if ((DayOfWeek.Sunday & dayMask)) {
    dowString += 'Sun ';
  }
  // tslint:disable-next-line:no-bitwise
  if ((DayOfWeek.Monday & dayMask)) {
    dowString += 'Mon ';
  }
  // tslint:disable-next-line:no-bitwise
  if ((DayOfWeek.Tuesday & dayMask)) {
    dowString += 'Tue ';
  }
  // tslint:disable-next-line:no-bitwise
  if ((DayOfWeek.Wednesday & dayMask)) {
    dowString += 'Wed ';
  }
  // tslint:disable-next-line:no-bitwise
  if ((DayOfWeek.Thursday & dayMask)) {
    dowString += 'Thu ';
  }
  // tslint:disable-next-line:no-bitwise
  if ((DayOfWeek.Friday & dayMask)) {
    dowString += 'Fri ';
  }
  // tslint:disable-next-line:no-bitwise
  if ((DayOfWeek.Saturday & dayMask)) {
    dowString += 'Sat ';
  }
  return dowString.substring(0, dowString.length - 1);
}

export function getFlag(day: number): DayOfWeek | undefined {
  switch (day) {
    case 0:
      return DayOfWeek.Sunday;
    case 1:
      return DayOfWeek.Monday;
    case 2:
      return DayOfWeek.Tuesday;
    case 3:
      return DayOfWeek.Wednesday;
    case 4:
      return DayOfWeek.Thursday;
    case 5:
      return DayOfWeek.Friday;
    case 6:
      return DayOfWeek.Saturday;
    default:
      return undefined;
  }
}

export function addDays(date: Date, amount: number): Date {
  const addTo = new Date(date);
  addTo.setDate(date.getDate() + amount);
  return addTo;
}

export function addMonths(date: Date, amount: number): Date  {
  const addTo = new Date(date);
  addTo.setMonth(date.getMonth() + amount);
  return addTo;
}

export function addYears(date: Date, amount: number): Date  {
  const addTo = new Date(date);
  addTo.setFullYear(date.getFullYear() + amount);
  return addTo;
}

export function getDateString(date: Date): string {
  return date.toISOString().split('T')[0];
}

export function getTime(time: number, date?: Date): Date  {
  const d = date ? new Date(date) : new Date(1900, 0, 1);
  d.setHours(Math.floor(time));
  d.setMinutes(Math.round((time % 1) * 60));
  return d;
}
