import { InjectionToken } from '@angular/core';

export const BRAND_OPTIONS = new InjectionToken<BrandOptions>('BRAND_OPTIONS');

export interface BrandOptions {
  isAvs: boolean;
  brandId: number;
  brandSlug: string;
  name: string;
  privacyLink: string;
  termsLink: string;
  contactLink: string;
  allLocationsLink: string;
  eSignatureAgreementLink: string;
  membershipAgreementLink: string;
  specialEventRequestFormLink: string;
  faqLink: string;
  useParkPhoneNumber: boolean;
  phoneNumber: string;
  productLevelUpsellText: string;
  attendeesText: string;
  showInvoiceTheme: boolean;
  showInvoiceHangout: boolean;
  showSpecialEventText: boolean;
  showChat: boolean;
  showLocationPopup: boolean;
}
