import { Inject, Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiService, ServiceBase } from '@unleashed/api/common';
import { APP_OPTIONS, AppOptions } from '@unleashed/common/config';
import { Booking, Package } from '@unleashed/models/booking';

@Injectable({
  providedIn: 'root'
})
export class PackageApiService extends ServiceBase {

  subnav = 'bookings/packages';

  constructor(@Inject(APP_OPTIONS) appOptions: AppOptions, private apiService: ApiService) {
    super(appOptions.unleashedApiUrl);
  }

  get(booking: Booking): Observable<Package[]> {
    let params = new HttpParams();
    params = params.append('cookieId', booking.cookieId ?? '')
      .append('parkId', booking.parkId)
      .append('preferredDate', booking.preferredDate?.toISOString())
      .append('requiresHandicap', booking.requiresHandicap);

    return this.apiService.get<Package[]>(this.buildUrl(), { params });
  }

}
