import { GuestOfHonor } from './guestOfHonor';
import { Item } from './item';

export interface Booking {
  // Step 1
  idempotencyKey?: string;
  cookieId?: string;
  parkId: string;
  requiresHandicap: boolean;
  guestsOfHonor: GuestOfHonor[];
  contactFirstName: string;
  contactLastName: string;
  phoneNumber: string;
  preferredDate: Date;
  preferredTimeId: number;
  // Step 2
  productLevelId?: number;
  minSubtotal?: number;
  // Step 3
  selectedDate?: Date;
  selectedPreferredTimeId?: number;
  selectedStartTime?: number;
  selectedEndTime?: number;
  baseBundleScheduleId?: number;
  extraBundleScheduleId?: number;
  adultBundleScheduleId?: number;
  timeBegan?: Date;
  timeSlotSubtotal?: number;
  // Step 4
  selectedResourceTypeId?: number;
  selectedResourcePrice?: number;
  scheduleDetailId?: number;
  // Step 5
  baseParticipantCount?: number;
  extraParticipantCount?: number;
  adultParticipantCount?: number;
  participantSubtotal?: number;
  promotionCode?: string;
  // Step 6
  bookingItems: Item[];
  step?: number;
  isDefault?: boolean;
  accountId?: number;
  depositAmount?: number;
  emailAddress?: string;
  // Special Event
  isSpecialEvent?: boolean;
  userWasLoggedInWhenApplyingPromo?: boolean;
  cartReleased?: Date;
}
