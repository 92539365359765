<div *ngIf="!error">
  <div id="invoice">
    <ng-container *ngIf="invoice$ | async as invoice; else skeleton">

      <div *ngIf="!invoice.isSpecialEvent" class="h1 fw-bold text-center text-primary mb-3 mt-4">{{ guestsOfHonorNames }}'s party is booked!</div>
      <div *ngIf="invoice.isSpecialEvent" class="h1 fw-bold text-center text-primary mb-3 mt-4">{{ invoice.organizationName }}</div>

      <div class="h6 text-center mt-3">Confirmation Code: {{this.bookingConfirmation | uppercase}}</div>

      <div class="noPrint">
        Please check your email for a confirmation and <button class="btn btn-link text-primary text-decoration-none pb-1
         pt-0 pe-0 ps-0" printSectionId="invoice" [useExistingCss]="true" ngxPrint>
        print</button> this screen for your records.

        <div class="center pt-2 pb-2">
          <p class="text-primary font-weight-bold">
            Please share the waiver link below with the other people in your party to expedite check-in.
          </p>
          <div class="input-group">
            <input type="text" class="form-control" value="{{waiverLink}}" #waiver readonly>
            <button class="btn btn-primary" type="button" (click)="copy(waiver)" alt="Copy">
              <fa-icon [icon]="faCopy" class="fa-lg"></fa-icon> Copy
            </button>
          </div>

        </div>

      </div>

      <div *ngIf="!invoice.isSpecialEvent">
        If you have any questions or need to make changes, please contact us at
        <a class="text-decoration-none text-primary" href="tel:{{phoneNumber}}">{{phoneNumber}}</a>.
      </div>

      <div *ngIf="invoice.isSpecialEvent">
        If you have any questions or need to make changes, please contact us at
        <a class="text-decoration-none text-primary" href="tel:{{phoneNumber}}">{{phoneNumber}}</a>.
      </div>

      <div class="card border-primary rounded-5 px-3 py-2 mt-3 mb-4">
        <ua-invoice [invoice]="invoice" [booking]="booking" [depositPaid]="true"></ua-invoice>
      </div>

      <div class="text-center mb-4 noPrint">
        <button class="btn btn-primary" (click)="makeConfetti()">More Confetti?</button>
      </div>

      <canvas id="confetti" class="confetti-canvas" width="{{width}}" height="{{height}}"></canvas>
    </ng-container>
  </div>
  <ng-template #skeleton>
    <div class="d-flex justify-content-center mt-3">
      <span class="h6 me-2">Confirmation number:</span>
      <span class="w-25">
        <ngx-skeleton-loader [count]="1" [theme]="{height: '19px'}"></ngx-skeleton-loader>
      </span>
    </div>
    <div class="card border-primary rounded-5 p-2 mt-3 mb-4">
      <ua-invoice-skeleton></ua-invoice-skeleton>
    </div>
  </ng-template>
</div>

<ua-error *ngIf="error" [errorCode]="error"></ua-error>
