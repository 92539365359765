import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { cardPaymentMethodPayload } from 'braintree-web-drop-in';
import { ApiService, ServiceBase } from '@unleashed/api/common';
import { APP_OPTIONS, AppOptions } from '@unleashed/common/config';

@Injectable({
  providedIn: 'root'
})
export class PaymentsApiService extends ServiceBase {

  subnav = 'booking-service/deposits';

  constructor(
    @Inject(APP_OPTIONS) appOptions: AppOptions,
    private apiService: ApiService
  ) {
    super(appOptions.bookingApiUrl);
  }

  processCartDeposit(cookieId: string, payload: cardPaymentMethodPayload, accessToken: string): Observable<string> {
    return this.apiService.post<string, cardPaymentMethodPayload>(
      this.buildUrl('carts', cookieId), payload, {headers: {Authorization: `Bearer ${accessToken}`}});
  }

  processBookingDeposit(confirmationCode: string, payload: cardPaymentMethodPayload, accessToken: string): Observable<string> {
    return this.apiService.post<string, cardPaymentMethodPayload>(
      this.buildUrl('bookings', confirmationCode), payload, {headers: {Authorization: `Bearer ${accessToken}`}});
  }
}
