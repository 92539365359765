import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ParkPickerComponent } from '@unleashed/ecommerce/components';

const routes: Routes = [
  {path: 'parks', component: ParkPickerComponent},
  {path: 'account', loadChildren: () => import('@unleashed/account/account.module').then(m => m.AccountModule)},
  {path: 'parks/:slug', loadChildren: () => import('@unleashed/booking/booking.module').then(m => m.BookingModule)},
  {path: 'waiver', loadChildren: () => import('@unleashed/waiver/waiver.module').then(m => m.WaiverModule)},
  {path: '', redirectTo: '/parks', pathMatch: 'full'},
  {path: '**', redirectTo: '/parks', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class EcommerceRoutingModule { }
