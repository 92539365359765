import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { selectOptionValidator } from '@unleashed/common/validators';
import { Booking, Item, ResourceTypeModifier } from '@unleashed/models/booking';

@Component({
  selector: 'ua-park-modifiers',
  templateUrl: './park-modifiers.component.html',
  styleUrls: ['./park-modifiers.component.scss']
})
export class ParkModifiersComponent implements OnInit {

  @Input() booking!: Booking;
  @Input() modifiers: ResourceTypeModifier[] = [];
  @Input() hasItemsToSelect?: boolean;
  @Output() bookingItems = new EventEmitter<Item[]>();
  @Output() formValid = new EventEmitter<boolean>();
  @Input() formDirty = false;

  displayItems: { name: string, quantity: number }[] = [];

  // used for the item select ddl
  formItemsParkProducts: { products: Item[] }[] = [];

  form: FormGroup = new FormGroup({
    items: new FormArray([])
  });

  get items(): FormArray {
    return this.form.get('items') as FormArray;
  }

  get groups(): FormGroup[] {
    return this.items.controls as FormGroup[];
  }

  constructor(private fb: FormBuilder) {
  }

  ngOnInit(): void {
    const existingItems = [...this.booking?.bookingItems?.filter(i => i.included)?.map(i => ({...i})) ?? []];
    this.modifiers.forEach(m => {
      if (m.products.length > 1) {
        for (let i = 0; i < m.quantity; i++) {
          const existingItem = existingItems?.find(x => x.productModifierTypeId === m.productModifierTypeId);
          this.addItem(m, !existingItem ? undefined : existingItem?.id, !existingItem ? undefined : existingItem?.parkProductId);
          if (existingItem) {
            existingItem.quantity--;
            if (!existingItem.quantity) {
              existingItems?.splice(existingItems.indexOf(existingItem), 1);
            }
          }
        }
      } else if (m.products.length > 0) {
        const existingItem = existingItems?.find(x => x.parkProductId === m.products[0].parkProductId);
        this.items.push(this.fb.group({
          id: existingItem?.id,
          included: true,
          quantity: m.quantity,
          productModifierTypeId: m.productModifierTypeId,
          parkProductId: m.products[0].parkProductId,
          ticketId: m.products[0].ticketId,
          scheduleDetailId: m.scheduleDetailId
        }));
        this.displayItems.push({name: m.products[0].parkProductName, quantity: m.quantity});
        this.formItemsParkProducts.push({products: m.products});
      }
    });

    this.formValid.emit(this.form.valid);
    this.handleChanges(this.form.value);

    this.form.valueChanges.subscribe(changes => {
      this.handleChanges(changes);
    });
  }

  handleChanges(changes: any): void {
    const items: Item[] = [];
    changes.items.forEach((i: Item) => {
      if (i.parkProductId) {
        const item = {...i, parkProductId: +i.parkProductId, price: 0};
        items.push(item);
      }
      if (i.ticketId) {
        const item = {...i, ticketId: +i.ticketId, price: 0};
        items.push(item);
      }
    });

    const uniqueItems: Item[] = [];

    items
      .map(i => i.parkProductId ?? i.ticketId)
      .filter((v, i, a) => a.indexOf(v) === i)
      .forEach(id => {
        const filteredItems = items.filter(i => (i.parkProductId && i.parkProductId === id) || (i.ticketId && i.ticketId === id));
        const quantity = filteredItems.map(i => i.quantity).reduce((a, b) => a + b);
        const modifierItem = this.modifiers.find(bi =>
          (filteredItems[0].parkProductId && filteredItems[0].parkProductId === bi.productModifierTypeId)
          || (filteredItems[0].ticketId && filteredItems[0].ticketId === bi.productModifierTypeId));
        if (modifierItem?.relatedBookingItems?.length) {
          modifierItem.relatedBookingItems.forEach((rbi: Item) => {
            uniqueItems.push({...filteredItems[0], quantity: rbi.quantity, bundleScheduleId: rbi.bundleScheduleId, id: rbi.id});
          });
        } else {
          uniqueItems.push({...filteredItems[0], quantity: filteredItems[0].quantity > 1 ? filteredItems[0].quantity : quantity});
        }
      });

    uniqueItems.forEach(i => {
      const mismatch = items.find(f => f.id === i.id
        && ((f.parkProductId && f.parkProductId !== i.parkProductId) || (f.ticketId && f.ticketId !== i.ticketId)));
      if (mismatch) {
          i.id = undefined;
      }
    });

    this.bookingItems.emit(uniqueItems);
    this.formValid.emit(this.form.valid);
  }

  private addItem(modifier: ResourceTypeModifier, itemId?: number, parkProductId?: number): void {

    // used for the item select ddl
    this.formItemsParkProducts.push({products: modifier.products});

    const fg = this.fb.group({
      id: itemId,
      included: true,
      quantity: 1,
      productModifierTypeId: modifier.productModifierTypeId,
      parkProductId: [parkProductId ?? 0, selectOptionValidator()],
      scheduleDetailId: modifier.scheduleDetailId
    });

    this.items.push(fg);
    this.displayItems.push({name: modifier.name, quantity: modifier.quantity});
  }

}
