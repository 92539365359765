import { Inject, Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { APP_OPTIONS, AppOptions } from '@unleashed/common/config';
import { ApiService, ServiceBase } from '@unleashed/api/common';
import { CustomEncoder } from '@unleashed/api/common/custom-encoder';
import { RegisteredAccount, User } from '@unleashed/models/account';

@Injectable({
  providedIn: 'root'
})
export class AccountsApiService extends ServiceBase {

  subnav = 'accounts-service/accounts';

  constructor(
    @Inject(APP_OPTIONS) appOptions: AppOptions,
    private apiService: ApiService
  ) {
    super(appOptions.accountApiUrl);
  }

  getUser(id: number, accessToken: string): Observable<User> {
    return this.apiService.get<User>(this.buildUrl(`${id}/user`), {headers: {Authorization: `Bearer ${accessToken}`}});
  }

  create(user: User): Observable<RegisteredAccount> {
    return this.apiService.post<RegisteredAccount, User>(this.buildUrl(''), user);
  }

  update(user: User, accessToken: string): Observable<User> {
    return this.apiService
      .put<User, User>(this.buildUrl(`${user.accountId}/user`), user, {headers: {Authorization: `Bearer ${accessToken}`}});
  }

  accountExists(emailAddress: string): Observable<boolean> {
    const params = new HttpParams({encoder: new CustomEncoder()})
      .append('email', emailAddress);

    return this.apiService.get<boolean>(this.buildUrl('exists'), { params });
  }

}
