import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService, ServiceBase } from '@unleashed/api/common';
import { APP_OPTIONS, AppOptions } from '@unleashed/common/config';
import { BookingConfiguration } from '@unleashed/models/booking/bookingConfiguration';

@Injectable({
  providedIn: 'root'
})
export class BookingConfigurationApiService extends ServiceBase {

  subnav = 'bookings/configuration';

  constructor(@Inject(APP_OPTIONS) appOptions: AppOptions, private apiService: ApiService) {
    super(appOptions.unleashedApiUrl);
  }

  get(): Observable<BookingConfiguration> {
    return this.apiService.get<BookingConfiguration>(this.buildUrl());
  }
}
