import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import {APP_OPTIONS, AppOptions, BRAND_OPTIONS, BrandOptions} from '@unleashed/common/config';
import { BusyService } from '@unleashed/services/common';
import { BookingSessionService } from '@unleashed/services/booking';
import {BrandsApiService} from '@unleashed/api/brand/brands-api.service';

@Component({
  selector: 'ua-ecommerce',
  templateUrl: './ecommerce.component.html',
  styleUrls: ['./ecommerce.component.scss']
})
export class EcommerceComponent implements OnInit {

  enableGtm: boolean;

  // FIXME location doesn't get initialized properly
  initialized = false;

  constructor(
    private gtmService: GoogleTagManagerService,
    private router: Router,
    private session: BookingSessionService,
    private busy: BusyService,
    private brandApi: BrandsApiService,
    @Inject(APP_OPTIONS) private appOptions: AppOptions,
    @Inject(BRAND_OPTIONS) private brandOptions: BrandOptions
  ) {
    this.enableGtm = appOptions.enableGtm;
  }

  ngOnInit(): void {
    this.busy.set('app', true);

    this.brandApi.getBrandBySlug(this.brandOptions.brandSlug).subscribe(b => {
      this.brandOptions.phoneNumber = b.phoneNumber;
      this.brandOptions.showChat = b.settings.showChat;
      this.brandOptions.showLocationPopup = b.settings.showLocationPopup;
      this.brandOptions.isAvs = b.settings.isAvs;
      b.links.forEach(l => {
        switch (l.linkTypeId) {
          case 1:
            this.brandOptions.allLocationsLink = l.url;
            break;
          case 2:
            this.brandOptions.privacyLink = l.url;
            break;
          case 3:
            this.brandOptions.termsLink = l.url;
            break;
          case 4:
            this.brandOptions.contactLink = l.url;
            break;
          case 5:
            this.brandOptions.eSignatureAgreementLink = l.url;
            break;
          case 6:
            this.brandOptions.membershipAgreementLink = l.url;
            break;
          case 8:
            this.appOptions.brandWebsiteUrl = l.url;
            break;
          case 9:
            this.brandOptions.faqLink = l.url;
            break;
          case 10:
            this.brandOptions.specialEventRequestFormLink = l.url;
            break;
          case 11:
            this.appOptions.familyPortalUrl = l.url;
            this.appOptions.accountPortalUrl = l.url;
            break;
          case 12:
            this.appOptions.storeUrl = l.url;
            break;
          case 13:
            this.appOptions.forgotPasswordUrl = l.url;
            break;
        }
      });
    });

    this.session.initialize()
      .subscribe(() => {
        this.initialized = true;
        this.busy.set('app', false);
      });

    if (this.enableGtm) {
      this.gtmService.addGtmToDom();
    }
  }

}
