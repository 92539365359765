import { InjectionToken } from '@angular/core';

export const APP_OPTIONS = new InjectionToken<AppOptions>('APP_OPTIONS');

export interface AppOptions {
  storeUrl: string;
  brandWebsiteUrl: string;
  uaAdminUrl: string;
  forgotPasswordUrl: string;
  accountPortalUrl: string;
  familyPortalUrl?: string;
  parkApiUrl: string;
  productApiUrl: string;
  invoiceApiUrl: string;
  accountApiUrl: string;
  mediaApiUrl: string;
  bookingApiUrl: string;
  unleashedApiUrl: string;
  salesForceUrl: string;
  salesForceUrlJs: string;
  salesForceBaseLiveAgentUrl: string;
  salesForceBaseLiveAgentContentUrl: string;
  salesForceLiveAgentSetupFlowUrl: string;
  salesForceKey: string;
  cookieName: string;
  bookingCookieName: string;
  enableGtm: boolean;
  auth: {
    issuer: string;
    clientId: string;
    clientSecret: string;
    scopes: string[];
    cookieOptions: {
      namespace: string;
      domain: string;
      duration: number;
      secure: boolean;
    }
  };
}
