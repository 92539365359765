<div *ngIf="!error">
  <div *ngIf="booking" class="mt-4">
    <ua-nav-bar (backClicked)="back()" (nextClicked)="next()" [nextDisabled]="!hangoutSelected"></ua-nav-bar>

    <ua-call-to-action class="text-center" template="Choose a Hangout for {0} Party" [booking]="booking" [possessive]="true" [showTime]="true"></ua-call-to-action>
    <ua-skeleton-card *ngIf="!hangoutsLoaded" [count]="3" [descriptionHeight]="24" [priceHeight]="32"></ua-skeleton-card>
    <div *ngFor="let hangout of hangouts; let i = index;">
      <div class="card mt-3 shadow border-2 rounded-5" [ngClass]="hangout.available > 0 ? 'border-primary' : ''">
        <div *ngIf="hangout.available === 0" class="text-primary sold-out d-flex justify-content-center align-items-center">
          <div class="rp-15">SOLD OUT</div>
        </div>
        <div class="card-body d-flex" [ngClass]="hangout.available > 0 ? '' : 'opaque text-dark'">
          <div class="d-flex flex-column w-100 me-3">
            <h3 [ngClass]="hangout.available > 0 ? 'text-primary' : ''">
              {{hangout.name}}
              <sup *ngIf="hangout.amenities.length">
                <button class="btn btn-link btn-sm p-0" (click)="showAmenitiesModal(hangout)">
                  <fa-icon [icon]="faInfoCircle"></fa-icon>
                </button>
              </sup>
            </h3>

            <p>{{hangout.description}}</p>
          </div>
          <ua-card-pricing class="ms-auto"
                           [price]="hangout.displayPrice"
                           [selected]="hangout.selected"
                           [style]="'exact'"
                           (selectClicked)="toggleSelection(i)"
                           [disabled]="hangout.available === 0"
                           [showSign]="true">
          </ua-card-pricing>
        </div>
      </div>
    </div>
  </div>
  <div class="py-2">
    <ua-nav-bar (backClicked)="back()" (nextClicked)="next()" [nextDisabled]="!hangoutSelected"></ua-nav-bar>
  </div>

  <ua-start-over></ua-start-over>
</div>

<ua-error *ngIf="error" [errorCode]="error"></ua-error>

<div id="amenitiesModal" class="modal" tabindex="-1">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body p-4">
        <h1 class="text-capitalize fs-1 fw-bold text-primary">Includes: </h1>
      <ul class="list-unstyled">
        <li *ngFor="let a of amenities"><h5><fa-icon class="text-primary me-2" [icon]="faCheck"></fa-icon>{{ a }}</h5></li>
      </ul>
      </div>
      <div class="align-self-center">
          <button class="btn btn-sm mt-2 mb-2 btn-primary" data-bs-dismiss="modal">Dismiss</button>
      </div>
    </div>
  </div>
</div>

