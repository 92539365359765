import { Component, OnInit } from '@angular/core';
import { NavigationSteps } from '@unleashed/models/booking';
import { BookingSessionService } from '@unleashed/services/booking';

@Component({
  selector: 'ua-start-over',
  templateUrl: './start-over.component.html',
  styleUrls: ['./start-over.component.scss']
})
export class StartOverComponent implements OnInit {

  constructor(private sessionService: BookingSessionService) { }

  ngOnInit(): void {
  }

  startOver(): void {
    this.sessionService.removeCart();
    this.sessionService.navigateToStep(NavigationSteps.Start);
  }
}
