import {Component, Input, OnInit, OnChanges, EventEmitter, Output, SimpleChanges} from '@angular/core';
import { Modal } from 'bootstrap';
import {PartyInvoice} from '@unleashed/models/booking';

@Component({
  selector: 'ua-location-confirmation',
  templateUrl: './location-confirmation.component.html',
})
export class LocationConfirmationComponent implements OnInit, OnChanges {
  locationConfirmationModal?: Modal;
  confirmDecisionModal?: Modal;
  @Input() invoice: PartyInvoice | undefined;
  @Input() triggerLocationPopup: boolean | undefined;
  @Output() confirmLocationEvent = new EventEmitter();
  @Output() restartEvent = new EventEmitter();

  ngOnInit(): void {
    const elementLocationConfirmation = document.getElementById('locationConfirmationModal');
    const elementConfirmDecision = document.getElementById('confirmDecisionModal');
    if (elementLocationConfirmation && elementConfirmDecision) {
      this.locationConfirmationModal = new Modal(elementLocationConfirmation);
      this.confirmDecisionModal = new Modal(elementConfirmDecision);
    }
  }
  ngOnChanges(changes: SimpleChanges ): void {
    if (changes.triggerLocationPopup && changes.triggerLocationPopup.currentValue === true) {
      this.locationConfirmationModal?.show();
    }
  }
  cancelLocation(): void {
    this.locationConfirmationModal?.hide();
    this.confirmDecisionModal?.show();
  }
  confirmLocation(): void {
    this.confirmLocationEvent.emit();
    this.locationConfirmationModal?.hide();
  }
  restartFromBeginning(): void {
    this.restartEvent.emit();
    this.locationConfirmationModal?.hide();
    this.confirmDecisionModal?.hide();
  }
  openLocationConfirmation(): void {
    this.locationConfirmationModal?.show();
  }
}
