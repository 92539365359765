<div *ngIf="booking && !invoice?.isSpecialEvent; else specialEventTime" [ngClass]="cssClass">
  <h5 class="fw-bold m-0 party-date">{{booking.selectedDate | date:'EEEE, MMMM d'}}</h5>
  <h5 class="fw-bold party-time">
    {{booking.selectedStartTime ?? 0 | time | date:'h:mm'}}
    {{' '}}
    <span class="fs-6 fw-bold">{{booking.selectedStartTime ?? 0 | time | date:'a'}}</span>
    {{' - '}}
    {{booking.selectedEndTime ?? 0 | time | date:'h:mm'}}
    {{' '}}
    <span class="fs-6 fw-bold">{{booking.selectedEndTime ?? 0 | time | date:' a'}}</span>
  </h5>
</div>

<ng-template #specialEventTime>
  <div class="align-content-end" [ngClass]="cssClass">
    <h5 class="fw-bold m-0 party-date">{{invoice?.date | date:'EEEE, MMMM d'}}</h5>
    <h5 class="fw-bold party-time">
      {{invoice?.startHour ?? 0 | time | date:'h:mm'}}
      {{' '}}
      <span class="fs-6 fw-bold">{{invoice?.startHour ?? 0 | time | date:'a'}}</span>
      {{' - '}}
      {{invoice?.endHour ?? 0 | time | date:'h:mm'}}
      {{' '}}
      <span class="fs-6 fw-bold">{{invoice?.endHour ?? 0 | time | date:' a'}}</span>
    </h5>
  </div>
</ng-template>
