import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phoneNumber'
})
export class PhoneNumberPipe implements PipeTransform {

  transform(value: string, args?: any): any {

    if (!value) {
      return;
    }

    let x = value.toString().replace(/[^0-9]/g, '');
    const prefix = '1';
    if (x.startsWith('1')) {
      x = x.substr(1, x.length - 1);
    }

    if (!x || x.length !== 10) {
      return value;
    }

    if (args === 'noFormatting') {
      return `${prefix}${x}`;
    }

    let formatted = `(${x.substring(0, 3)}) ${x.substring(3, 6)}-${x.substring(6, 10)}`;
    if (prefix && (!args || !args.hidePrefix)) {
      formatted = `+${prefix} ` + formatted;
    }
    return formatted;
  }
}
