import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'time'
})
export class TimePipe implements PipeTransform {

  transform(value: number): Date {
    const date = new Date(1900, 0, 1);
    date.setHours(Math.floor(value));
    date.setMinutes(Math.round((value % 1) * 60));
    return date;
  }

}
