import {
  Component,
  ElementRef,
  EventEmitter, OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { BusyService } from '@unleashed/services/common';

@Component({
  selector: 'ua-justifi',
  templateUrl: './justifi.component.html',
  styleUrls: ['./justifi.component.scss']
})

export class JustifiWrapperComponent implements OnInit {

  @ViewChild('justifiElem') justifiElem?: ElementRef;
  @Output() justifiReady = new EventEmitter<ElementRef>();


  constructor(
    private busyService: BusyService,
  ) {
  }

  ngOnInit(): void {
    this.busyService.set('checkout', true);
  }

  emitReady(): void {
    this.justifiReady.emit(this.justifiElem);
    this.busyService.set('checkout', false);
  }
}
