import { PreferredTime } from './preferredTime';

export interface BookingConfiguration {
  preferredTimes: PreferredTime[];
  maxGuestsOfHonor: number;
  minAdvanceDays: number;
  maxAdvanceDays: number;
  spotWarningThreshold: number;
  threeDSecureEnabled: boolean;
  justifiSubAccountId?: string;
}
