<div class="modal-header bg-primary text-white">
  <h5 class="mb-0">{{header}}</h5>
</div>
<div class="modal-body p-4">
  <div class="py-3">
    <p class="mb-0">{{message}}</p>
  </div>
  <div class="row gap-2">
    <button type="button" class="col btn btn-block btn-primary" (click)="alertAct()">{{actionLabel ?? 'Submit'}}
    </button>
    <button type="button" class="col btn btn-outline-primary btn-block"
            (click)="alertCancel()">{{cancelLabel ?? 'Dismiss'}}</button>
  </div>
</div>
