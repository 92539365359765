<div *ngFor="let _ of cards">
  <div class="card mt-3 shadow border-2 rounded-3">
    <div class="card-body d-flex">
      <div *ngIf="hasImage" class="me-3">
        <div class="product-image">
          <ngx-skeleton-loader [theme]="{height: '90px'}"></ngx-skeleton-loader>
        </div>
      </div>
      <div class="d-flex flex-column w-100 me-3">
        <ngx-skeleton-loader [theme]="{height: '30px'}"></ngx-skeleton-loader>
        <ngx-skeleton-loader [theme]="{height: descriptionHeightString}"></ngx-skeleton-loader>
      </div>
      <div>
        <ngx-skeleton-loader [theme]="{height: priceHeightString, width: priceWidthString}"></ngx-skeleton-loader>
        <ngx-skeleton-loader class="mt-3" [theme]="{height: '34px'}"></ngx-skeleton-loader>
      </div>
    </div>
  </div>
</div>
