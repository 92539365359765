<div class="h1 text-primary text-center fw-bold">
  Log In
</div>

<form class="mt-4" [formGroup]="logInForm" (ngSubmit)="logIn()">
  <div class="mb-3">
    <input id="emailAddress" type="email" class="form-control" formControlName="emailAddress"
           placeholder="Email Address">
    <ua-display-validation-errors name='Email address' [control]="logInForm.controls.emailAddress" [force]="formDirty">
    </ua-display-validation-errors>
  </div>

  <div class="mb-3">
    <input id="password" type="password" class="form-control" formControlName="password"
           placeholder="Password">
    <ua-display-validation-errors name='Password' [control]="logInForm.controls.password" [force]="formDirty">
    </ua-display-validation-errors>
    <br *ngIf="!logInForm.controls.password.valid">
    <a href="{{forgotPasswordUrl}}" target="_blank" class="btn btn-sm btn-link ps-0">Forgot Password?</a>
  </div>

  <div class="d-flex justify-content-between mb-3">
    <button type="submit" class="btn btn-sm btn-primary flex-grow-1 me-2">Log In</button>
    <button type="button" class="btn btn-sm btn-outline-primary flex-grow-1 ms-2" (click)="cancel()">Cancel</button>
  </div>
</form>
