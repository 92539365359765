export interface Item {
  id?: number;
  parkProductId?: number;
  ticketId?: number;
  parkProductName: string;
  productModifierTypeId: number;
  quantity: number;
  price: number;
  included: boolean;
  imageUri?: string;
  isPartyAddon?: boolean;
  description?: string;
  maxQuantity?: number;
  bundleScheduleId?: number;
  isActive?: boolean;
}

export interface CartItem extends Item {
  cartId: number;
}

export interface BookingItem extends Item {
  bookingId: number;
}
