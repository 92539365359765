import { Component, Inject, Input } from '@angular/core';
import { Booking, PartyInvoice } from '@unleashed/models/booking';
import { BRAND_OPTIONS, BrandOptions } from '@unleashed/common/config';

@Component({
  selector: 'ua-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent {

  @Input() invoice?: PartyInvoice;
  @Input() depositPaid = false;
  @Input() booking?: Booking;
  showTheme: boolean;
  showHangouts: boolean;

  constructor(@Inject(BRAND_OPTIONS) brandOptions: BrandOptions) {
    this.showHangouts = brandOptions.showInvoiceHangout;
    this.showTheme = brandOptions.showInvoiceTheme;
  }

}
