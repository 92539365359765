import { Component, OnInit } from '@angular/core';
import { combineLatest } from 'rxjs';
import { take } from 'rxjs/operators';
import { BusyService } from '@unleashed/services/common';
import { Booking, Hangout, HangoutMinimumGrouped, NavigationSteps, PreferredTime, Timeslot } from '@unleashed/models/booking';
import { BookingSessionService } from '@unleashed/services/booking';
import { HangoutApiService, TimeslotsApiService } from '@unleashed/api/booking';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { faCheck, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Modal } from 'bootstrap';

@Component({
  selector: 'ua-timeslots',
  templateUrl: './timeslots.component.html',
  styleUrls: ['./timeslots.component.scss']
})
export class TimeslotsComponent implements OnInit {

  faInfoCircle = faInfoCircle;
  faCheck = faCheck;

  booking?: Booking;
  timeslots: Timeslot[] = [];
  timeslotSelected = false;
  preferredTimes: PreferredTime[] = [];
  hangoutsGrouped: HangoutMinimumGrouped = {};
  selectionChanged = false;
  slotsLoaded = false;
  minPrice = 0;
  error?: number;
  amenities: string[] = [];
  amenitiesModal?: Modal;

  constructor(
    private sessionService: BookingSessionService,
    private timeslotsApiService: TimeslotsApiService,
    private busyService: BusyService,
    private gtmService: GoogleTagManagerService,
    private hangoutApiService: HangoutApiService
  ) { }

  ngOnInit(): void {
    const element = document.getElementById('amenitiesModalTimeSlots');
    if (element) {
      this.amenitiesModal = new Modal(element);
    }

    combineLatest([
      this.sessionService.getConfig(),
      this.sessionService.getBooking()
    ])
      .pipe(take(1))
      .subscribe(([config, booking]) => {
        this.preferredTimes = config.preferredTimes;
        this.booking = booking;

        this.loadTimeslots(booking);
      }, err => this.error = err.status);
  }

  scrollToSelectedOrPreferred(index?: number): void {
    let elem: HTMLElement | null;
    if (index === -1) {
      const preferredTime = this.preferredTimes.find(t => t.id === this.booking?.preferredTimeId);
      index = this.timeslots.findIndex(ts => ts.startTime >= (preferredTime?.startHour ?? 0));
    }

    // Give Angular a moment to render the array
    setTimeout(() => {
      elem = document.getElementById('card-' + index);
      elem?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, 100);
  }

  loadTimeslots(booking?: Booking): void {
    if (!booking) {
      return;
    }
    this.timeslotsApiService.get(booking)
      .subscribe(timeslots => {
        timeslots = timeslots.filter(t => t.isActive);
        const selectedIndex = timeslots.findIndex(t => t.startTime === booking.selectedStartTime && t.endTime === booking.selectedEndTime);
        for (let i = 0; i < timeslots.length; i++) {
          if (selectedIndex === i) {
            this.timeslotSelected = true;
            timeslots[i].selected = true;
            timeslots[i].displayPrice = 0;
          } else {
            timeslots[i].displayPrice = selectedIndex >= 0
              ? timeslots[i].price - timeslots[selectedIndex].price
              : timeslots[i].price - (booking.minSubtotal ?? 0);
          }
        }
        this.timeslots = timeslots;
        this.slotsLoaded = true;
        this.scrollToSelectedOrPreferred(selectedIndex);
      }, err => this.error = err.status);

    this.hangoutApiService.getHangoutsMinimum(booking).subscribe(hangouts => {
      const tempHangouts: HangoutMinimumGrouped = {};
      //get the lowest price hangout for each start hour
      for (const hangout of hangouts) {
        if (hangout?.startHour !== undefined) {
          if (tempHangouts[hangout.startHour] === undefined || tempHangouts[hangout.startHour].price > hangout.price) {
            tempHangouts[hangout.startHour] = hangout;
          }
        }
      }
      this.hangoutsGrouped = tempHangouts
    });
  }

  toggleSelection(index: number): void {
    this.selectionChanged = true;
    let subtotal = 0;
    this.timeslots[index].selected = !this.timeslots[index].selected;
    if (this.timeslots[index].selected) {
      subtotal = this.timeslots[index].price;
      this.timeslots[index].displayPrice = 0;
    } else if (this.booking) {
      subtotal = this.minPrice ?? 0;
      this.timeslots[index].displayPrice = this.timeslots[index].price - subtotal;
    }

    for (let i = 0; i < this.timeslots.length; i++) {

      if (i === index) {
        continue;
      }

      this.timeslots[i].selected = false;
      if (this.timeslots[index].selected) {
        this.timeslots[i].displayPrice = this.timeslots[i].price - this.timeslots[index].price;
      } else if (this.booking) {
        this.timeslots[i].displayPrice = this.timeslots[i].price - subtotal;
      }
    }

    this.sessionService.setSubtotal(subtotal);
    this.timeslotSelected = this.timeslots.find(s => s.selected) !== undefined;
  }

  back(): void {
    if (!this.booking) {
      return;
    }
    this.booking.step = 2;
    this.navigateAndSave(this.booking, NavigationSteps.Packages);
  }

  next(): void {
    if (!this.booking) {
      return;
    }
    this.booking.step = 3;
    this.navigateAndSave(this.booking, NavigationSteps.Hangouts);
  }

  navigateAndSave(booking: Booking, step: NavigationSteps): void {
    const selected = this.timeslots.find(s => s.selected);

    this.gtmService.pushTag({
      'event': 'timeSelectClicked',
      'selectedTime': `${selected?.startTime} - ${selected?.endTime}`
    });

    if (!this.selectionChanged) {
      this.sessionService.navigateToStep(step);
      return;
    }

    booking.selectedDate = selected?.selected
      ? booking.selectedDate ?? booking.preferredDate
      : undefined;
    booking.selectedStartTime = selected?.startTime;
    booking.selectedEndTime = selected?.endTime;
    booking.baseBundleScheduleId = selected?.baseBundleScheduleId;
    booking.extraBundleScheduleId = selected?.extraBundleScheduleId;
    booking.adultBundleScheduleId = selected?.adultBundleScheduleId;
    booking.timeBegan = new Date();
    booking.minSubtotal = selected?.price;

    booking.baseParticipantCount = undefined;
    booking.extraParticipantCount = undefined;
    booking.adultParticipantCount = undefined;
    booking.bookingItems = [];
    booking.selectedResourceTypeId = undefined;
    booking.selectedResourcePrice = undefined;
    booking.scheduleDetailId = undefined;

    this.sessionService.upsertCart(booking)
      .subscribe(
        () => {
          this.busyService.set('timeslots', false);
          this.sessionService.navigateToStep(step);
        },
        err => {
          this.error = err.status;
          this.busyService.set('timeslots', false);
        });
  }

  updateDate(event: Date): void {
    if (!this.booking || !this.booking.preferredTimeId || !this.booking.productLevelId) {
      return;
    }

    this.selectionChanged = true;

    this.booking.selectedDate = event;
    this.booking.selectedStartTime = undefined;
    this.booking.selectedEndTime = undefined;
    this.sessionService.setSubtotal(this.booking.minSubtotal ?? 0);
    this.timeslotSelected = false;

    this.slotsLoaded = false;
    this.timeslots = [];
    this.loadTimeslots(this.booking);
  }

  updateMinPrice(event: number): void {
    this.minPrice = event;
  }

  showAmenitiesModal(hangout: Hangout): void {
    this.amenitiesModal?.show();
    this.amenities = hangout.amenities;
  }
}
