import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { APP_OPTIONS, AppOptions, BRAND_OPTIONS, BrandOptions } from '@unleashed/common/config';
import { BusyService } from '@unleashed/services/common';
import { AuthorizationService } from '@unleashed/services/account';
import { take } from 'rxjs/operators';

@Component({
  selector: 'ua-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit {

  logInForm: FormGroup;
  formDirty = false;
  forgotPasswordUrl: string;
  brandId: number;
  @Input() username = '';
  @Output() cancelClicked = new EventEmitter<void>();

  constructor(
    private busy: BusyService,
    private auth: AuthorizationService,
    @Inject(APP_OPTIONS) private appOptions: AppOptions,
    @Inject(BRAND_OPTIONS) private brandOptions: BrandOptions
  ) {
    this.logInForm = new FormGroup({
      emailAddress: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required])
    });
    this.brandId = brandOptions.brandId;
    this.forgotPasswordUrl = `${appOptions.forgotPasswordUrl}?brandId=${this.brandId}`;
  }

  ngOnInit(): void {
    this.logInForm.patchValue({emailAddress: this.username});

    if (this.username) {
      setTimeout(() => {
        const passwordInput = document.getElementById('password');
        if (passwordInput) {
          passwordInput.focus();
        }
      }, 100);
    }
  }

  logIn(): void {
    this.formDirty = true;
    if (!this.logInForm.valid) {
      return;
    }

    this.busy.set('login', true);
    this.auth.authorizeUser(this.logInForm.value.emailAddress, this.logInForm.value.password)
      .pipe(take(1))
      .subscribe(() => {
        this.busy.set('login', false);
      });
  }

  cancel(): void {
    this.cancelClicked.emit();
  }

}
