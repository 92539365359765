import { Inject, Injectable } from '@angular/core';
import { ApiService, ServiceBase } from '@unleashed/api/common';
import { APP_OPTIONS, AppOptions } from '@unleashed/common/config';
import { combineLatest, Observable, of } from 'rxjs';
import { AuthorizationService } from '@unleashed/services/account';
import { concatMap, map, take } from 'rxjs/operators';
import { ApiHelpers } from '@unleashed/api/utils';
import { PaymentMethod } from '@unleashed/models/account/PaymentMethod';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class VaultedCardApiService extends ServiceBase {


  constructor(
    @Inject(APP_OPTIONS) appOptions: AppOptions,
    private apiService: ApiService,
    private auth: AuthorizationService
  ) {
    super(appOptions.unleashedApiUrl);
  }

  getVaultedCards(paymentProcessorVersion?: number, subAccountId?: string): Observable<PaymentMethod[] | undefined> {
    return this.auth.getAccessToken()
      .pipe(
        take(1),
        concatMap(token => {
          if (!token) {
            return of(undefined);
          }
          return this.apiService.get<PaymentMethod[]>(
            `${this.baseUrl}/accounts/my/billing-info/vault?paymentProcessorVersion=${paymentProcessorVersion}&subAccountId=${subAccountId}`,
            { headers: { Authorization: `Bearer ${token}` } }
          )
            .pipe(map( paymentMethods => {
              return paymentMethods;
            }));
        })
      );
  }
}
