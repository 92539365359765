import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { BusyService } from '@unleashed/services/common';

@Component({
  selector: 'ua-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {

  busy$?: Observable<boolean>;

  constructor(private busyService: BusyService) { }

  ngOnInit(): void {
    this.busy$ = this.busyService.get();
  }

}
