import { Inject, Injectable } from '@angular/core';
import { ApiService, ServiceBase } from '@unleashed/api/common';
import { APP_OPTIONS, AppOptions } from '@unleashed/common/config';
import { Waiver } from '@unleashed/models/account/waiver';
import { combineLatest, Observable, of } from 'rxjs';
import { AuthorizationService } from '@unleashed/services/account';
import { concatMap, map, take } from 'rxjs/operators';
import { ApiHelpers } from '@unleashed/api/utils';

@Injectable({
  providedIn: 'root'
})
export class AccountWaiversApiService extends ServiceBase {

  subnav = 'accounts-service/accounts';

  constructor(
    @Inject(APP_OPTIONS) appOptions: AppOptions,
    private api: ApiService,
    private auth: AuthorizationService
  ) {
    super(appOptions.accountApiUrl);
  }

  preview(parkId?: string): Observable<Waiver | undefined> {
    if (!parkId) {
      return of(undefined);
    }
    return combineLatest([
      this.auth.getUser(),
      this.auth.getAccessToken()])
      .pipe(
        take(1),
        concatMap(([user, token]) => {
          if (!user.accountId || !token) {
            return of(undefined);
          }
          return this.api.get<Waiver>(this.buildUrl(user.accountId, 'locations', parkId, 'waivers', 'preview'),
            {headers: {Authorization: `Bearer ${token}`}})
            .pipe(map(w => ({
              ...w,
              coveredPeople: w?.coveredPeople?.map(p => ({
                ...p,
                dateOfBirth: p.dateOfBirth ? ApiHelpers.stripTimeZone(p.dateOfBirth) : undefined
              })),
              guardian: {
                ...w?.guardian,
                dateOfBirth: w?.guardian?.dateOfBirth ? ApiHelpers.stripTimeZone(w?.guardian?.dateOfBirth) : undefined
              }
            })));
        }),
      );
  }

}
