export interface SpecialEvent {
  bookingId?: number;
  organizationName?: string;
  contactFirstName?: string;
  contactLastName?: string;
  phoneNumber?: string;
  basePrice?: number;
  baseParticipantCount?: number;
  extraPrice?: number;
  extraParticipantCount?: number;
  isTaxExempt?: boolean;
  taxCode?: string;
}
