import { Pipe, PipeTransform } from '@angular/core';
import {CurrencyPipe} from '@angular/common';

@Pipe({
  name: 'price'
})
export class PricePipe implements PipeTransform {

  transform(value?: number, args?: string, shortenNumber?: boolean): string | undefined | null {
    if (value === undefined || value === null) {
      return;
    }

    let currencyCode = args ?? 'USD';
    const fractionDigits = value % 1 === 0 && shortenNumber ? 0 : 2;
    if (currencyCode !== 'CAD') {
      const currencyPipe = new CurrencyPipe(window.navigator.language);
      return currencyPipe.transform(value, currencyCode, 'symbol', `1.${fractionDigits}-${fractionDigits}`);
    } 

    // Use Intl.NumberFormat for CAD
    return new Intl.NumberFormat(window.navigator.language, {
      style: 'currency',
      currency: currencyCode,
      currencyDisplay: 'narrowSymbol',
      minimumFractionDigits: fractionDigits,
      maximumFractionDigits: fractionDigits
    }).format(value) + ' ' + currencyCode;
  }
}
