import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService, ServiceBase } from '@unleashed/api/common';
import { APP_OPTIONS, AppOptions } from '@unleashed/common/config';
import { User, UserRegistration } from '@unleashed/models/account';

@Injectable({
  providedIn: 'root'
})
export class SecurityApiService extends ServiceBase {

  subnav = 'user-service/users';

  constructor(
    @Inject(APP_OPTIONS) appOptions: AppOptions,
    private apiService: ApiService
  ) {
    super(appOptions.auth.issuer);
  }

  register(username: string, password: string, registrationToken: string): Observable<User> {
    return this.apiService
      .post<User, UserRegistration>(this.buildUrl('register'), {username, password, registrationToken});
  }
}
