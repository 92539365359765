import { Booking } from '@unleashed/models/booking';

export class ApiHelpers {

  public static fixUpBookingDates(booking: Booking): Booking {
    if (booking.timeBegan) {
      booking.timeBegan = new Date(Date.parse(booking.timeBegan as unknown as string));
    }
    booking.preferredDate = this.stripTimeZone(booking.preferredDate);
    if (booking.selectedDate) {
      booking.selectedDate = this.stripTimeZone(booking.selectedDate);
    }
    booking.guestsOfHonor.forEach(g => g.dateOfBirth = this.stripTimeZone(g.dateOfBirth));
    return booking;
  }

  public static stripTimeZone(dateString: any): Date {
    const components = dateString.replace('T', '-').split('-');
    const month = parseInt(components[1], 0);
    return new Date(components[0], month - 1, components[2]);
  }

}
